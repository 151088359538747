import React, {Suspense, useEffect, useRef, useState} from 'react';
import { Route, Routes, Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import fetchData from '../common/fetchData'

import Header from '../common/Header';
import Footer from '../common/Footer';
import LazyMedia from '../common/LazyMedia';
import CookieModal from "../common/CookieModal";

import logo_fwmtl from "../../assets/partners/logo_fwmtl.svg";
import logo_neovendi from "../../assets/partners/logo_neovendi.webp";
import logo_db from "../../assets/partners/logo_db.svg";
import logo_mfund from "../../assets/partners/logo_mfund.png";


function About() {
    let { name } = useParams();
    const nameEmpty = () => name !== 'marco' && name !== 'nikolas';

    const personRef = useRef<HTMLDivElement>()

    const cockpitUrl = 'https://rwc-project.de/cockpit' //process.env.NODE_ENV==='production'? 'http://' + window.location.hostname + ':8888';
    const cockpitKey = 'API-079298e13fc1c7a76ceeebf2f1e2702a305a65ca' // Frontend-Key

    const [i18n, setI18n] = useState('de_DE')
    const [aboutSite, setAboutSite] = useState<any>({})
    useEffect(() => {
        console.log('>> About loaded.')

        fetchData(cockpitUrl, cockpitKey, 'about', setAboutSite, true, true, i18n, false);
    }, [])

    return (
        <section className="About bg-white text-black font-montserrat">
            { process.env.NODE_ENV === 'production' ? '' :
                <div className="fixed bg-white text-black z-50 bottom-0 right-0">
                    <div className="hidden sm:block md:hidden">sm</div>
                    <div className="hidden md:block lg:hidden">md</div>
                    <div className="hidden lg:block xl:hidden">lg</div>
                    <div className="hidden xl:block 2xl:hidden">xl</div>
                    <div className="hidden 2xl:block">2xl</div>
                </div>
            }

            <Helmet>
                <meta charSet="utf-8" />
                <title>Über uns - RWC Project</title>
                <meta name="Wir, Nikolas & Marco, zwei Studenten aus der Nähe von Frankfurt, haben es uns zur Aufgabe gemacht, Einsatzfahrten von Hilfsorganisationen zu optimieren." />
            </Helmet>

            <CookieModal />

            <Header />
            <main className="pt-8 pb-32">
                <div className="px-resp">
                    <h1 className="text-3xl font-semibold">{ aboutSite?.title }</h1>
                    <h2 className="text-lg font-medium mb-2 mt-16">{ aboutSite?.subtitle }</h2>
                    <p dangerouslySetInnerHTML={{__html:aboutSite?.content_top}}></p>
                </div>
                <div ref={personRef}></div>
                <div className={`flex flex-col md:flex-row items-center md:justify-evenly space-y-8 md:space-y-0 ${!nameEmpty()?'bg-turquoise-lightest my-8 py-8 duration-300':'my-16'} px-resp transition-all`}>
                    { name !== 'nikolas' ?
                        <div className="flex flex-col items-center md:w-1/2">
                            <figure className="relative">
                                <Link to={name==='marco'?'.':'marco'} onClick={()=>personRef.current.scrollIntoView({behavior: "smooth"})}>
                                    <LazyMedia
                                        className="bg-grey-lightest aspect-square w-64 rounded-full object-cover"
                                        cockpitUrl={cockpitUrl}
                                        type="image"
                                        assetLink={aboutSite?.marco_image}
                                    />
                                </Link>
                                <figcaption className="text-center mt-4">
                                    <span className="font-semibold">Marco Scalese</span>
                                    <span className="block">Gründer</span>
                                </figcaption>
                                <Link to={name==='marco'?'./..':'marco'}
                                      className={`absolute top-0 left-full border-2 border-turquoise text-3xl text-center aspect-square w-10 rounded-full ${name==='marco'?'text-turquoise rotate-45':'bg-turquoise text-white'} transition-transform duration-300`}
                                      onClick={()=>personRef.current.scrollIntoView({behavior: "smooth"})}
                                >+</Link>
                            </figure>
                        </div>
                        : <div className="flex flex-col justify-center md:w-1/2">
                            <p className="" dangerouslySetInnerHTML={{__html:aboutSite?.nikolas_text}}></p>
                        </div>
                    }
                    { name !== 'marco' ?
                        <div className="flex flex-col items-center md:w-1/2">
                            <figure className="relative">
                                <Link to={name==='nikolas'?'.':'nikolas'} onClick={()=>personRef.current.scrollIntoView({behavior: "smooth"})}>
                                    <LazyMedia
                                        className="bg-grey-lightest aspect-square w-64 rounded-full object-cover"
                                        cockpitUrl={cockpitUrl}
                                        type="image"
                                        assetLink={aboutSite?.nikolas_image}
                                    />
                                </Link>
                                <figcaption className="text-center mt-4">
                                    <span className="font-semibold">Nikolas Hofmann</span>
                                    <span className="block">Gründer</span>
                                </figcaption>
                                <Link to={name==='nikolas'?'./..':'nikolas'}
                                      className={`absolute top-0 left-full border-2 border-turquoise text-3xl text-center aspect-square w-10 rounded-full ${name==='nikolas'?'text-turquoise rotate-45':'bg-turquoise text-white'} transition-transform duration-300`}
                                      onClick={()=>personRef.current.scrollIntoView({behavior: "smooth"})}
                                >+</Link>
                            </figure>
                        </div>
                        : <div className="flex flex-col justify-center md:w-1/2">
                            <p dangerouslySetInnerHTML={{__html:aboutSite?.marco_text}}></p>
                        </div>
                    }
                </div>
                <p className="px-resp" dangerouslySetInnerHTML={{__html:aboutSite?.content_bottom}}></p>
            </main>

            <div className="flex flex-row justify-between items-center bg-grey-lightest px-resp py-6 *:h-20">
                <img src={logo_db} alt="" className="py-6" />
                <img src={logo_neovendi} alt="" className="py-4" />
                <img src={logo_mfund} alt="" className="py-4" />
                <img src={logo_fwmtl} alt="" className="" />
            </div>

            <Footer theme={'light'} />
        </section>
    );
}

export default About;
