import React, { useRef, useState, useEffect } from 'react';
import {
    Link,
} from "react-router-dom";

import { ArrowLongLeftIcon } from '@heroicons/react/24/solid'


import imgFirefighterWithFiles from '../../../assets/firefighter_with_files.svg';

import Infotext from '../Infotext'


function General(props) {
    const form = useRef(null)

    return (
        <form ref={form} className="ReportRequest-General">
            <div className="flex flex-row"> {/* fix the full width */}
                <Link className="flex flex-row items-center border-b-1 border-black mb-16" to="../" state={{step:0}}>
                    <ArrowLongLeftIcon className="stroke-0.5 h-6 mr-2" />
                    Zurück
                </Link>
            </div>
            <div className="flex flex-col md:flex-row">
                <div className="md:w-3/5">
                    <h2 className="text-2xl font-semibold mb-8">Über Ihre Feuerwehr</h2>
                    <div className="">{ props.formContent?.address ?
                        <>
                            {props.formContent.address.name}<br />
                            {props.formContent.address.street} {props.formContent.address.street_number} <br />
                            {props.formContent.address.postal_code} {props.formContent.address.locality}  {props.formContent.address.sublocality !== null ? props.formContent.address.sublocality : ''}
                        </> : ''
                    }</div>
                    <Link to="../" state={{step:0}} className="inline-block pt-3 text-sm underline">Ändern</Link>
                </div>
                <div className="relative flex flex-col justify-center items-center md:items-end md:w-2/5">
                    <img src={imgFirefighterWithFiles} alt="Feuerwehrmann mit Formular" className="md:absolute h-56 mt-8 mb-16 md:my-0"/>
                </div>
            </div>
            <ul className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-6 mt-16 mb-8">
                {[
                    ['Freiwillige Feuerwehr', 'choice-ff'],
                    ['Berufsfeuerwehr', 'choice-bf'],
                    ['Werkfeuerwehr', 'choice-wf'],
                    ['Sonstige', 'choice-other'],
                ].map(([name, id], index) => (
                    <li key={"fw-type"+index} className="flex flex-row items-center space-x-2">
                        <input id={id} name="fw-type" type="radio" className="bg-grey-lightest text-turquoise focus:ring-turquoise" value={name} required={true}
                               checked={props.formContent?.fwType === name}
                               onChange={e=>props.setFormContent(f => { return {...f, 'fwType': e.target.value } })} />
                        <label htmlFor={id}>{ name }</label>
                    </li>
                ))}
            </ul>
            <Infotext text="Alle folgenden Angaben sollen sich ausschließlich auf den oben genannten Standort (nur Ihre Wache) beziehen." />
            <div className="flex flex-row mb-8">
                <div className="flex flex-col w-1/2 md:w-1/3 xl:w-1/4">
                    <span className="mb-4">Mannschaftsstärke</span>
                    <ul className="space-y-2">
                        {[
                            '< 10',
                            '11 - 20',
                            '21 - 30',
                            '31 - 50',
                            '51 - 80',
                            '81 - 120',
                            '> 120'
                        ].map((num, index) => (
                            <li key={"strength-team"+index} className="flex flex-row items-center space-x-2">
                                <input id={"choice-strength-team"+index} key={"choice-strength-team"+index} name="strength-team" type="radio" className="bg-grey-lightest text-turquoise focus:ring-turquoise" value={num} required={true}
                                       checked={props.formContent?.strengthTeam === num}
                                       onChange={e=>props.setFormContent(f => { return {...f, 'strengthTeam': e.target.value } })} />
                                <label htmlFor={"choice-strength-team"+index}>{ num }</label>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="flex flex-col w-1/2 md:w-1/3 xl:w-1/4">
                    <span className="mb-4">Jährliche Einsätze</span>
                    <ul className="space-y-2">
                        {[
                            '< 10',
                            '11 - 40',
                            '41 - 80',
                            '81 - 150',
                            '151 - 300',
                            '301 - 500',
                            '> 500'
                        ].map((num, index) => (
                            <li key={"amount-alarms"+index} className="flex flex-row items-center space-x-2">
                                <input id={"choice-amount-alarms"+index} name="amount-alarms" type="radio" className="bg-grey-lightest text-turquoise focus:ring-turquoise" value={num} required={true}
                                       checked={props.formContent?.amountAlarms === num}
                                       onChange={e=>props.setFormContent(f => { return {...f, 'amountAlarms': e.target.value } })} />
                                <label htmlFor={"choice-amount-alarms"+index}>{ num }</label>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="flex flex-col mb-8">
                <span className="mb-4">Verwendet Ihre Feuerwehr eine App zur Alarmierung über die Mobiltelefone neben der üblichen Alarmierung über Pager?</span>
                <ul className="flex flex-row items-center justify-start space-x-6">
                    {[
                        ['Ja', 'choice-pagerapp'],
                        ['Nein', 'choice-nopagerapp']
                    ].map(([name, id], index) => (
                        <li key={"pager-app"+index} className="flex flex-row items-center space-x-2">
                            <input id={id} name="pager-app" type="radio" className="bg-grey-lightest text-turquoise focus:ring-turquoise" value={name} required={true}
                                   checked={props.formContent?.pagerApp === (name==='Ja')}
                                   onChange={e=>props.setFormContent(f => { return {...f, 'pagerApp': e.target.value==='Ja', 'pagerAppName': e.target.value==='Ja'?props.formContent?.pagerAppName:null} })} />
                            <label htmlFor={id}>{ name }</label>
                        </li>
                    ))}
                </ul>
                <div className={`relative ${props.formContent?.pagerApp?'':'hidden'} mt-4`}>
                    <input type="text" size={42} id="pager-app-name" className="bg-grey-lightest border-0 appearance-none focus:outline-none focus:ring-0 px-4 pb-2 pt-5 rounded-xl peer" placeholder=" " required={false}
                           value={props.formContent?.pagerAppName}
                           onChange={e=>props.setFormContent(f => { return {...f, 'pagerAppName': e.target.value } })} />
                    <label htmlFor="pager-app-name" className="absolute text-grey-lighter duration-300 transform -translate-y-3.5 scale-75 top-3.5 z-10 origin-[0] left-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3.5">Verwendete App (optional)</label>
                </div>
            </div>
            <Link to="../carpool" state={{step:2}} className="inline-block bg-turquoise hover:bg-turquoise-lighter text-white border-2 border-turquoise hover:border-turquoise-lighter transition duration-150 px-6 py-2 rounded-full"
                  onClick={e=>{if (!form.current.reportValidity()) e.preventDefault()}}>weiter</Link>
        </form>
    );
}

export default General;
