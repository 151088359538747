export default async function FetchData(url:string, key:string, model:string, callback:any, isSingleton:boolean, isJson:boolean, i18n:string,  logIt:boolean=false) {
    await fetch(`${url}/api/content/${isSingleton?'item':'items'}/${model}?locale=` + i18n, {
            'method': 'GET',
            headers: { 'api-key': key }
        })
        .then(response => isJson?response.json():response.text())
        .then(data => {
            callback(data);
            logIt ? console.log('fetchData() ' + model + ' fetched.', data) : console.log('fetchData() ' + model + ' fetched.')
        });
};