import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

// import App from './components/AppOld/App';
import UnderConstruction from './components/under-construction/UnderConstruction';
import NotFound from './components/no-match/NotFound';
import ReportRequest from './components/report-request/ReportRequest';
import PrivacyPolicy from './components/legal/PrivacyPolicy';
import TermsAndConditions from './components/legal/TermsAndConditions';
import Imprint from './components/legal/Imprint';
import CookieDeclaration from "./components/legal/CookieDeclaration";
import Credentials from './components/legal/Credentials';
import Blog from './components/blog/Blog';
import Main from './components/main/Main';

import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./components/about/About";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/main" element={<Main />} />
              <Route path="/about/" element={<About />} />
              <Route path="/about/:name" element={<About />} />
              <Route path="/blog/*" element={<Blog />} />
              <Route path="/bericht-anfordern/*" element={<ReportRequest />} />
              <Route path="/datenschutz" element={<PrivacyPolicy />} />
              <Route path="/agb" element={<TermsAndConditions />} />
              <Route path="/impressum" element={<Imprint />} />
              <Route path="/cookies" element={<CookieDeclaration />} />
              <Route path="/referenzen" element={<Credentials />} />
              <Route path="/" element={<UnderConstruction />} />
              <Route path="*" element={<NotFound />} />
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

/*
Size values for TailwindCss:
16px ≙ 4
24px ≙ 6
32px ≙ 8
48px ≙ 12
64px ≙ 16
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
