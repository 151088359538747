import React, {useState} from 'react';
import {Link, NavLink} from "react-router-dom";

import logo from '../../assets/logo.svg';
import logo_minimal from '../../assets/logo_minimal.svg';

function Header(props) {
    const [isMenuOpen, setMenuOpen] = useState(false);

    return (
        <header className={`Header ${props.darkBg?'bg-black/40 backdrop-blur pt-4 lg:pt-8':'pt-8'} font-montserrat ${isMenuOpen?(props.darkBg?'h-screen ':'')+'mb-8 shadow-lg lg:shadow-none':''} lg:mb-16`}>
            <div className={`flex flex-col lg:flex-row justify-between lg:border-b-2 ${props.darkBg?'lg:border-white':'lg:border-black'} px-resp`}>
                <div className={`relative flex flex-row lg:block justify-between items-center lg:h-8 lg:px-4 lg:px-0 ${props.darkBg?'pb-4 lg:pb-8':'pb-8'} lg:pb-0`}>
                    <Link to="/">
                        <img src={logo} className={`lg:relative lg:top-1.5 lg:-left-2 ${props.darkBg?'':'bg-white'} h-16 lg:px-2`} alt="RWC-Logo" />
                    </Link>
                    <div className="relative lg:hidden box-content drop-shadow h-8 w-8 p-4" onClick={_=>setMenuOpen(s => !s)}>
                        <div className={`absolute ${props.darkBg?'bg-white':'bg-black'} h-1 w-8 transition-all duration-300 ${isMenuOpen?'top-7 rotate-45':'top-4 rotate-0'}`}></div>
                        <div className={`absolute ${props.darkBg?'bg-white':'bg-black'} h-1 w-8 transition-all duration-300 ${isMenuOpen?'top-7 scale-0 opacity-0':'top-7 scale-100 opacity-100'}`}></div>
                        <div className={`absolute ${props.darkBg?'bg-white':'bg-black'} h-1 w-8 transition-all duration-300 ${isMenuOpen?'top-7 -rotate-45':'top-10 rotate-0'}`}></div>
                    </div>
                </div>
                <div className={`flex flex-col lg:flex-row lg:self-end lg:space-x-8 lg:translate-y-1 text-lg lg:text-base overflow-y-hidden ${isMenuOpen?'':'h-0 lg:h-auto'}`}>
                    {[
                        ['Das Projekt', '/main'],
                        ['Über uns', '/about'],
                        ['Neuigkeiten', '/blog'],
                        ['Auswertung Anfordern', '/bericht-anfordern'],
                    ].map(([title, url, disabled], index) => (
                        <NavLink key={"nav"+index}
                                 to={url}
                                 className={({ isActive, isPending}) => "flex flex-row items-center border-t-1 border-black/10 py-2 lg:pt-0 " + (isActive ? "font-semibold lg:border-t-0 lg:border-b-4 lg:border-turquoise" : "lg:border-t-0") + (title==="Neuigkeiten"?" cursor-not-allowed":"") }
                                 onClick={title==="Neuigkeiten"?e=>e.preventDefault():undefined}
                        >
                            <div className="lg:hidden bg-turquoise h-12 w-1 py-3 mr-8"></div>
                            <div>{ title }</div>
                        </NavLink>
                    ))}
                </div>
            </div>
        </header>
    );
}

export default Header;
