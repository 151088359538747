import React, {useState} from 'react';

function LazyMedia(props) {


    return (
        <>
            { props.assetLink && props.type === 'image' ?
                <img src={props.cockpitUrl+'/storage/uploads'+props.assetLink?.path}
                     alt={props.assetLink?.description}
                     className={props.className} />
                : null
            }
            { props.assetLink && props.assetLink?.type === 'video' ?
                <video className={props.className} {...props.attrs }>
                    { props.assetLink ?
                        <source src={props.cockpitUrl+'/storage/uploads'+props.assetLink?.path}
                                type={props.assetLink?.mime} />
                        : null
                    }
                    { props.fallback ?
                        <source src={props.cockpitUrl+'/storage/uploads'+props.fallback?.path}
                                type={props.fallback?.mime} />
                        : null
                    }
                </video>
                : null
            }
        </>
    );
}

export default LazyMedia;
