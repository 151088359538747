import React, {useEffect, useState} from 'react';

import './loaderAnimation.css';


function Loader(props) {
    return (
        <div id="loader" className={`fixed z-50 top-0 left-0 flex flex-col justify-center items-center bg-black w-screen h-screen pointer-events-none transition-opacity duration-300 ease-in ${props.isVisible ? 'opacity-0' : 'opacity-100'}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 174.34 211.792">
                <g id="a">
                    <path className="c"
                          d="M171.145,85.958h-45.657c0,21.158-13.92,33.964-41.481,33.964H47.258V51.994h36.749c.801,0,1.588,.013,2.366,.034V15.246H2.158V185.446l2.542,2.542,22.136,22.136h20.423v-54.287h41.481l37.583,54.287h48.441l-43.708-62.639c25.334-10.857,40.089-32.294,40.089-61.526Z"/>
                </g>
                <g id="b">
                    <circle className="a" cx="98.171" cy="74.161" r="11.756"/>
                    <g>
                        <polygon className="d"
                                 points="98.171 75.065 98.248 75.065 98.248 74.988 98.248 74.457 98.171 74.457 98.171 75.065"/>
                        <g>
                            <path className="c"
                                  d="M149.787,23.449c-13.528-13.528-31.841-21.19-50.954-21.364V19.2c14.532,.132,28.456,5.958,38.742,16.244,10.123,10.123,15.913,23.771,16.221,38.054h17.325c-.404-18.785-8.02-36.736-21.333-50.049Z"/>
                            <path className="b"
                                  d="M128.518,44.718c-7.887-7.887-18.547-12.371-29.685-12.524v15.957c6.906,.151,13.506,2.955,18.401,7.85,4.681,4.681,7.442,10.921,7.808,17.496h15.965c-.377-10.808-4.817-21.108-12.489-28.78Z"/>
                        </g>
                    </g>
                </g>
            </svg>
            <div className="text-white text-xl mt-8">Inhalte werden geladen
                <span className="dot a">.</span>
                <span className="dot b">.</span>
                <span className="dot c">.</span>
            </div>
        </div>
    );
}

export default Loader;
