import React from 'react';
import {Link} from "react-router-dom";

function Footer(props) {
    return (
        <div className={`Footer ${props.theme=='light'?'bg-white text-black':'bg-grey-darkest text-white'} text-sm`}>
            <div className={`flex flex-col lg:flex-row justify-between space-y-8 lg:space-y-0 lg:items-center ${props.theme=='light'?'bg-grey-lightest':'bg-grey-darker'} px-resp pt-8 pb-12`}>
                <div className="space-y-2">
                    <span className="text-base uppercase font-semibold">RWC Project</span>
                    <p>Wir gehen Umwege, damit Sie es nie wieder müssen.</p>
                </div>
                {/*<div className="space-y-2">
                    <span className="uppercase text-turquoise">Unterseiten</span>
                    <div className="space-x-4">
                        <Link to="">Home</Link>
                        <Link to="">Über uns</Link>
                        <Link to="">Blog</Link>
                        <Link to="">Auswertung anfordern</Link>
                        <Link to="">Kontakt</Link>
                    </div>
                </div>*/}
                <div className="space-y-2">
                    <span className="uppercase text-turquoise">Kontaktiere uns gerne</span>
                    <p>contact@rwc-project.de</p>
                </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-between px-resp py-4">
                <div className="flex flex-col lg:flex-row lg:space-x-8 lg:order-first">
                    <Link to="/impressum">Impressum</Link>
                    <Link to="/datenschutz">Datenschutz</Link>
                    <Link to="/agb">AGB</Link>
                    <Link to="/cookies">Cookie-Erklärung</Link>
                    <Link to="/referenzen">Lizenzen und Mitwirkende</Link>
                </div>
                <span className="uppercase mt-4 lg:mt-0">Copyright 2022-2024</span>
            </div>
        </div>
    );
}

export default Footer;
