import React from 'react';
import {Link} from "react-router-dom";

import logo from '../../assets/logo.svg';
import logo_mono from '../../assets/logo_mono.svg';
import firefighter_behind_barrier from '../../assets/firefighter_behind_barrier.svg';

function NotFound() {
    return (
        <section className="NotFound relative md:grid grid-cols-2 gap-8 bg-grey-darkest text-white font-montserrat h-screen px-6 md:px-16 pt-4 md:pt-16">
            <div className="clip-path absolute left-0 top-0 hidden md:flex flex-row justify-center items-center bg-turquoise h-full w-1/2">
                <img src={firefighter_behind_barrier} className="mb-8 h-56" alt="Feuerwehrmann hinter Bahnschranke" />
            </div>
            <div className="dummy"></div>
            <div className="flex flex-col justify-between h-full">
                <div className="flex justify-center md:justify-end">
                    <img src={logo_mono} className="h-24 md:h-28" alt="RWC-Logo" />
                </div>
                <div className="xl:pr-16">
                    <h1 className="text-6xl md:text-8xl font-bold mb-4">404</h1>
                    <h2 className="text-lg md:text-2xl mb-16">Oops… Hier geht es leider nicht weiter.</h2>
                    <h2 className="text-lg md:text-2xl mb-8">Diese Seite existiert nicht.</h2>
                    <a href="/" className="inline-block bg-turquoise hover:bg-turquoise-lighter border-2 border-turquoise hover:border-turquoise-lighter transition duration-150 px-6 py-2">Zurück zur Startseite</a>
                </div>
                <div className="flex flex-col xl:flex-row justify-between align-center text-grey-lighter mb-4">
                    <p>© 2023 RWC - Railway Clairvoyant</p>
                    <p>contact@rwc-project.de</p>
                </div>
            </div>

        </section>
    );
}

export default NotFound;
