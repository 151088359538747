function Infotext(props) {
    return (
        <div className="Infotext relative flex flex-row mb-8">
            <div className="bg-turquoise-lightest pr-4 rounded-l-2xl">
                <span className="flex flex-row justify-center items-center bg-turquoise text-white ring-2 ring-white h-7 w-7 rounded-full">i</span>
            </div>
            <p className="bg-turquoise-lightest pr-4 py-0.5 rounded-r-2xl">{ props.text }</p>
        </div>
    );
}

export default Infotext;
