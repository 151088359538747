import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileLines } from '@fortawesome/free-solid-svg-icons'
import { ArrowLongRightIcon, ArrowUturnDownIcon, XMarkIcon, UserIcon } from '@heroicons/react/24/solid'

import '../../index.css'

import fetchData from '../common/fetchData'

// import Header from './Header';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Loader from '../common/Loader';
import CookieModal from "../common/CookieModal";
import LazyMedia from "../common/LazyMedia";

import icon_epi from '../../assets/epilepsy_warning.svg'
import icon_wifi from '../../assets/icons/wifi.png'
import icon_orbit from '../../assets/icons/orbit.png'
import icon_simcard from '../../assets/icons/simcard.png'
import icon_chip from '../../assets/icons/chip.png'
import icon_security from '../../assets/icons/security.png'

import logo_minimal_tur from '../../assets/logo_minimal_tur.svg';
import logo_linkedin from '../../assets/logo_linkedin.svg'


function Main() {
    let cockpitUrl = 'https://rwc-project.de/cockpit' //process.env.NODE_ENV==='production'? 'http://' + window.location.hostname + ':8888';

    const animationFolder = require.context('../../assets/animationFrames/', true);

    const loadingStepsCollection = {
        firstElement: (index, _) => index===0,
        threeElements: (index, len) => index===0 || index===Math.floor(len / 2) || index===len-1, // first, middle, last
        oddElements: (index, _) => index%2>0,
        evenElements: (index, _) => index%2===0,
        allElements: (index, _) => true
    }
    const lsc = loadingStepsCollection; // better code readability

    const [animations, setAnimations] = useState({
        'firetruck': {
            'count': 212,
            'path': (i) => './firetruck_city_16-9/firetruck_' + addZeros(i, 4) + '.webp',
            'framesBuffer': [],
            'frames': [],
            'scrollPos': 0, // %
            'curr': 0, // currently active frame (index based on y coordinate)
            'loadingStep': -1, // starting negative to match indices starting at 0
            'loadingSteps': [lsc.firstElement, lsc.oddElements, lsc.evenElements]
        },
        'firetruckB': {
            'count': 212,
            'path': (i) => './firetruck_city_16-9/firetruck_b_' + addZeros(i, 4) + '.webp',
            'framesBuffer': [],
            'frames': [],
            'curr': 0, // currently active frame (index based on y coordinate)
            'loadingStep': -1,
            'loadingSteps': [lsc.firstElement, lsc.oddElements, lsc.evenElements]
        },
        'implementation': {
            'count': 203,
            'path': (i) => './implementation/implementation_' + addZeros(i, 4) + '.jpg',
            'framesBuffer': [],
            'frames': [],
            'curr': 0, // currently active frame (index based on y coordinate)
            'loadingStep': -1,
            'loadingSteps': [lsc.firstElement, lsc.allElements]
        },
        'terminal': {
            'count': 4,
            'path': (i) => './terminal/terminal_' + addZeros(i, 4) + '.png',
            'framesBuffer': [],
            'frames': [],
            'curr': 0, // currently active frame (index based on y coordinate)
            'loadingStep': -1,
            'loadingSteps': [lsc.firstElement, lsc.allElements]
        },
    })

    function useInterval(callback, delay) {
        const savedCallback = useRef(null);

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }

    const navRef = useRef<HTMLDivElement>(null);
    const firetruckAnimationRef = useRef<HTMLDivElement>(null);
    const firetruckCanvasRef = useRef<HTMLCanvasElement>(null);
    const firetruckBCanvasRef = useRef<HTMLCanvasElement>(null);
    const routesSectionRef = useRef<HTMLDivElement>(null);
    const routesTextRef = useRef<HTMLDivElement>(null);
    const routesVideoRef = useRef<HTMLVideoElement>(null);
    const mapVideoRef = useRef<HTMLVideoElement>(null);
    const statsProportionVideoRef = useRef<HTMLVideoElement>(null);
    const statsTimeVideoRef = useRef<HTMLVideoElement>(null);
    const reportBannerRef = useRef<HTMLDivElement>(null);
    const reportBannerTextRef = useRef<HTMLParagraphElement>(null);
    const reportBannerCircleRef = useRef<HTMLDivElement>(null);
    const reportBannerIconRef = useRef(null);
    const implementationAnimationRef = useRef<HTMLDivElement>(null);
    const implementationWrapperRef = useRef<HTMLDivElement>(null);
    const implementationText1Ref = useRef<HTMLDivElement>(null);
    const implementationText2Ref = useRef<HTMLDivElement>(null);
    const implementationText3Ref = useRef<HTMLDivElement>(null);
    const implementationText4Ref = useRef<HTMLDivElement>(null);
    const implementationCanvasRef = useRef<HTMLCanvasElement>(null);
    const terminalAnimationRef = useRef<HTMLDivElement>(null);
    const terminalCanvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        // looped through very fast but images take their time loading individually
        for (let i = 0; i < animations.firetruckB.loadingSteps.length; i++) {
            preloadAnimation(animations.firetruckB, i);
            preloadAnimation(animations.firetruck, i);
        }
        for (let i = 0; i < animations.terminal.loadingSteps.length; i++) {
            preloadAnimation(animations.terminal, i);
        }
        for (let i = 0; i < animations.implementation.loadingSteps.length; i++) {
            preloadAnimation(animations.implementation, i);
        }
    }, [])

    const [loadingStepSum, setLoadingStepSum] = useState(0);
    useInterval(() => { // check loading steps
        setLoadingStepSum(0);
        const firetruckBLoadingStep = getLoadingStep(animations.firetruckB)
        const firetruckLoadingStep = getLoadingStep(animations.firetruck)
        const implementationLoadingStep = getLoadingStep(animations.implementation)
        const terminalLoadingStep = getLoadingStep(animations.terminal)
        setLoadingStepSum(firetruckBLoadingStep+firetruckLoadingStep+implementationLoadingStep+terminalLoadingStep)
        // console.log(loadingStepSum)

        if (firetruckBLoadingStep > animations.firetruckB.loadingStep && firetruckLoadingStep > animations.firetruck.loadingStep) {
            updateLoadingStep(animations.firetruckB, firetruckBLoadingStep)
            //updateLoadingStep(animations.firetruck, firetruckLoadingStep) // was this the cause of the layer-difference in a race condition?
            updateLoadingStep(animations.firetruck, firetruckBLoadingStep)
        }
        if (implementationLoadingStep > animations.implementation.loadingStep) updateLoadingStep(animations.implementation, implementationLoadingStep)
        if (terminalLoadingStep > animations.terminal.loadingStep) updateLoadingStep(animations.terminal, terminalLoadingStep)

    }, (loadingStepSum >= animations.firetruckB.loadingSteps.length+animations.firetruck.loadingSteps.length+animations.implementation.loadingSteps.length+animations.terminal.loadingSteps.length-4) ? null : 1000);

    const preloadAnimation = async (animation, loadingStep:number) => {
        // console.log('[preloadAnimation] start.')
        const animationKey = Object.entries(animations).find(a => a[1] === animation)[0];
        const newAnimation = {...animation}
        const frameCount = animation.count;
        for (let i = 0; i < frameCount; i++) {
            if (!animation.loadingSteps[loadingStep](i, animation.loadingSteps.length)) continue; // checks if frame is in currentLoadingStep
            const frameImg = new Image();
            frameImg.src = animationFolder(animation.path(i));
            newAnimation.framesBuffer[i] = frameImg;
        }
        setAnimations(currAnimations => { return {...currAnimations, [animationKey]: newAnimation} })
        // console.log('[preloadAnimation] loaded.')
    }

    useEffect(() => { // drawAnimations
        drawAnimation(firetruckBCanvasRef.current, animations.firetruckB)
        drawAnimation(firetruckCanvasRef.current, animations.firetruck)
        drawAnimation(implementationCanvasRef.current, animations.implementation)
        drawAnimation(terminalCanvasRef.current, animations.terminal)
    }, [animations])

    const drawAnimation = async (canvas, animation) => {
        // console.log('[drawAnimation] start.')
        // console.time('drawAnimation')
        const currFrame = animation.frames[animation.curr];
        if (currFrame === undefined || !currFrame.complete) return; // image not loaded yet

        const ctx = canvas.getContext('2d')
        canvas.height = currFrame.height;
        canvas.width = currFrame.width;

        ctx.clearRect(0, 0, currFrame.width, currFrame.height);
        ctx.drawImage(currFrame, 0, 0);
        // console.timeEnd('drawAnimation')
        // console.log('[drawAnimationFrame] set.')
    }

    const addZeros = (num, digits) => {
        const floatPart = num%1
        const intPart = num - floatPart
        const numLen = intPart.toString().length
        if (numLen >= digits || num < 0) return num;
        else return "0".repeat(digits-numLen) + num.toString();
    }

    const pickImage = (scrollPos, imageArr) => { /* if scrollPos between 0 and 1, it's in the viewport */
        if (scrollPos <= 0) return 0;
        if (scrollPos >= 1) return imageArr.length-1;

        const steps = 1 / imageArr.length;
        return Math.floor(scrollPos / steps);
    }

    const getLoadingStep = (animation) => {
        let loadingStep = -1;
        for (let i = 0; i < animation.loadingSteps.length; i++) {
            const framesInLoadingStep = animation.framesBuffer.filter((_, index) => animation.loadingSteps[i](index, animation.loadingSteps.length))
            const framesLoaded = framesInLoadingStep.filter(f => f.complete)
            if (framesInLoadingStep.length === framesLoaded.length) loadingStep = i;
        }
        return loadingStep;
    }

    const updateLoadingStep = (animation, loadingStep) => {
        const animationKey = Object.entries(animations).find(a => a[1] === animation)[0];
        const stepsTillNow = animation.loadingSteps.filter((_, index) => index <= loadingStep)
        setAnimations(currAnimations => {
            return {
                ...currAnimations,
                [animationKey]: {
                    ...animation,
                    'frames': [...animation.framesBuffer].filter((_, index) => stepsTillNow.some(s => s(index))), // every frame that is in this or one of the previous loading steps gets included
                    'loadingStep': loadingStep
                }
            }
        })

        return loadingStep;
    }


    const [i18n, setI18n] = useState('de_DE')
    const [mainSite, setMainSite] = useState<any>({})

    useEffect(() => {
        console.log('>>  Main loaded.')

        fetchData(cockpitUrl,'', 'main', setMainSite, true, true, i18n, false);
    }, [])


    const [visTaggings, setVisTaggings] = useState(false);
    const [clampTerminalDescription, setClampTerminalDescription] = useState(true);


    gsap.registerPlugin(ScrollTrigger);
    const wrap = useRef<HTMLDivElement>()
    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            gsap.to(firetruckAnimationRef.current, {
                scrollTrigger: {
                    trigger: firetruckAnimationRef.current,
                    scroller: wrap.current,
                    start: "top top",
                    end: () => '+=' + (firetruckAnimationRef.current.offsetHeight - firetruckCanvasRef.current.offsetHeight),
                    scrub: 0,
                    // markers: true,
                    onUpdate: e => {
                        // console.log("progress", e.progress)
                        setAnimations(currAnimation => { return {...currAnimation, 'firetruckB': {...currAnimation.firetruckB, 'curr': pickImage(e.progress, currAnimation.firetruckB.frames), 'scrollPos': e.progress}} })
                        setAnimations(currAnimation => { return {...currAnimation, 'firetruck': {...currAnimation.firetruck, 'curr': pickImage(e.progress, currAnimation.firetruckB.frames), 'scrollPos': e.progress}} })

                    }
                },
            });

            gsap.to(routesTextRef.current, {
                opacity: 1,
                scrollTrigger: {
                    trigger: routesSectionRef.current,
                    scroller: wrap.current,
                    start: "top top",
                    end: () => '+=' + (routesSectionRef.current.offsetHeight - routesVideoRef.current.offsetHeight),
                    scrub: 0,
                    markers: false
                }
            });

            gsap.from(reportBannerRef.current, {
                width: 'auto',
                scrollTrigger: {
                    trigger: reportBannerRef.current,
                    scroller: wrap.current,
                    start: "center 85%",
                    end: "+=10%",
                    scrub: true
                }
            });

            gsap.from(reportBannerCircleRef.current, {
                height: 0,
                width: 0,
                scrollTrigger: {
                    trigger: reportBannerRef.current,
                    scroller: wrap.current,
                    start: "center 90%",
                    end: "+=5%",
                    scrub: true,
                    invalidateOnRefresh: true
                }
            });

            gsap.from(reportBannerIconRef.current, {
                scale: 0,
                rotate: 90,
                scrollTrigger: {
                    trigger: reportBannerRef.current,
                    scroller: wrap.current,
                    start: "center 75%",
                    end: "+=5%",
                    scrub: true
                }
            });

            gsap.to(reportBannerTextRef.current, {
                opacity: 1,
                display: "block",
                scrollTrigger: {
                    trigger: reportBannerRef.current,
                    scroller: wrap.current,
                    start: "center 75%",
                    end: "+=5%",
                    scrub: true
                }
            });

            gsap.to(implementationAnimationRef.current, {
                scrollTrigger: {
                    trigger: implementationAnimationRef.current,
                    scroller: wrap.current,
                    start: "top top",
                    end: () => '+=' + (implementationAnimationRef.current.offsetHeight-implementationWrapperRef.current.offsetHeight),
                    scrub: 0,
                    markers: false,
                    onUpdate: e => {
                        // console.log("progress", e.progress, Math.floor(e.progress * animations.implementation.count))
                        setAnimations(currAnimation => { return {...currAnimation, 'implementation': {...currAnimation.implementation, 'curr': pickImage(e.progress, currAnimation.implementation.frames), 'scrollPos': e.progress}} })
                    }
                }
            });

            let tlImplementationWrapper = gsap.timeline()
                .set(implementationWrapperRef.current, {
                    scaleX: 0.7,
                    scaleY: 0.7,
                    borderRadius: 24
                })
                .to(implementationWrapperRef.current, {
                    scaleX: 1,
                    scaleY: 1,
                    borderRadius: 0,
                    scrollTrigger: {
                        trigger: implementationAnimationRef.current,
                        scroller: wrap.current,
                        start: "top top",
                        end: "5% top",
                        scrub: 0,
                        markers: false,
                    }
                })
                /*.from(implementationWrapperRef.current, {
                    scaleX: 1,
                    scaleY: 1,
                    borderRadius: 0,
                    scrollTrigger: {
                        trigger: implementationAnimationRef.current,
                        scroller: wrap.current,
                        start: "98% bottom",
                        end: "bottom bottom",
                        scrub: 0,
                        markers: false,
                    }
                })*/


            let tlImplementationText1 = gsap.timeline()
                .set(implementationText1Ref.current, { opacity: 0 })
                .to(implementationText1Ref.current, {
                    opacity: 1,
                    scrollTrigger: {
                        trigger: implementationAnimationRef.current,
                        scroller: wrap.current,
                        start: "10% top",
                        end: "12% top",
                        scrub: 0,
                        markers: false,
                    }
                })
                .from(implementationText1Ref.current, {
                    opacity: 1,
                    scrollTrigger: {
                        trigger: implementationAnimationRef.current,
                        scroller: wrap.current,
                        start: "18% top",
                        end: "20% top",
                        scrub: 0,
                        markers: false,
                    }
                })

            let tlImplementationText2 = gsap.timeline()
                .set(implementationText2Ref.current, { opacity: 0 })
                .to(implementationText2Ref.current, {
                    opacity: 1,
                    scrollTrigger: {
                        trigger: implementationAnimationRef.current,
                        scroller: wrap.current,
                        start: "23% top",
                        end: "25% top",
                        scrub: 0,
                        markers: false,
                    }
                })
                .from(implementationText2Ref.current, {
                    opacity: 1,
                    scrollTrigger: {
                        trigger: implementationAnimationRef.current,
                        scroller: wrap.current,
                        start: "38% top",
                        end: "40% top",
                        scrub: 0,
                        markers: false,
                    }
                })

            let tlImplementationText3 = gsap.timeline()
                .set(implementationText3Ref.current, { opacity: 0 })
                .to(implementationText3Ref.current, {
                    opacity: 1,
                    scrollTrigger: {
                        trigger: implementationAnimationRef.current,
                        scroller: wrap.current,
                        start: "43% top",
                        end: "45% top",
                        scrub: 0,
                        markers: false,
                    }
                })
                .from(implementationText3Ref.current, {
                    opacity: 1,
                    scrollTrigger: {
                        trigger: implementationAnimationRef.current,
                        scroller: wrap.current,
                        start: "58% top",
                        end: "60% top",
                        scrub: 0,
                        markers: false,
                    }
                })

            let tlImplementationText4 = gsap.timeline()
                .set(implementationText4Ref.current, { opacity: 0 })
                .to(implementationText4Ref.current, {
                    opacity: 1,
                    scrollTrigger: {
                        trigger: implementationAnimationRef.current,
                        scroller: wrap.current,
                        start: "63% top",
                        end: "65% top",
                        scrub: 0,
                        markers: false,
                    }
                })
                .from(implementationText4Ref.current, {
                    opacity: 1,
                    scrollTrigger: {
                        trigger: implementationAnimationRef.current,
                        scroller: wrap.current,
                        start: "78% top",
                        end: "80% top",
                        scrub: 0,
                        markers: false,
                    }
                })

            gsap.to(terminalAnimationRef.current, {
                scrollTrigger: {
                    trigger: terminalAnimationRef.current,
                    scroller: wrap.current,
                    start: "top top",
                    end: () => '+=' + (terminalAnimationRef.current.offsetHeight/3),
                    scrub: 0,
                    markers: false,
                    onUpdate: e => {
                        // console.log("progress", e.progress, terminalAnimationRef.current.offsetHeight, terminalCanvasRef.current.offsetHeight)
                        setAnimations(currAnimation => { return {...currAnimation, 'terminal': {...currAnimation.terminal, 'curr': pickImage(e.progress, currAnimation.terminal.frames), 'scrollPos': e.progress}} })
                    }
                }
            });
        }, wrap.current)
        return () => ctx.revert(); // cleanup
    }, []);


    useEffect(() => { ScrollTrigger.refresh() }, [mainSite, animations.firetruck.loadingStep, animations.implementation.loadingStep, animations.terminal.loadingStep])


    return (
        <section ref={wrap} className="Main relative bg-grey-darkest text-white text-md md:text-lg lg:text-md xl:text-base 2xl:text-xl font-montserrat h-screen overflow-y-scroll select-none"> {/*onScroll={handleScroll}*/}
            { process.env.NODE_ENV === 'production' ? '' :
                <div className="fixed bg-white text-black z-50 bottom-0 right-0">
                    <div className="hidden sm:block md:hidden">sm</div>
                    <div className="hidden md:block lg:hidden">md</div>
                    <div className="hidden lg:block xl:hidden">lg</div>
                    <div className="hidden xl:block 2xl:hidden">xl</div>
                    <div className="hidden 2xl:block">2xl</div>
                </div>
            }

            <Helmet>
                <meta charSet="utf-8" />
                <title>RWC Project</title>
                <meta name="description" content="Technische Lösungen zur Vorhersage der Öffnungszeiten von Bahnübergängen für Hilfsorganisationen." />
            </Helmet>

            <CookieModal />

            <Loader isVisible={animations.firetruck.loadingStep>=0 && mainSite?.hero_title} />
            <div id="firetruckAnimation" className={`relative bg-grey-darkest ${animations.firetruck.loadingStep>0 ? 'h-3screen md:h-2screen' : 'h-screen'}`} ref={firetruckAnimationRef}>
                <div className={`fixed z-40 w-full transition duration-500 ${animations.firetruck.scrollPos>0.05&&animations.firetruck.scrollPos<1 ? 'opacity-0' : 'duration-300'}`} ref={navRef}>
                    <Header darkBg={true} />
                </div>
                <div className="MainHero absolute top-0 z-30 flex flex-col bg-gradient-to-b from-black/40 md:from-black/20 xl:from-transparent to-transparent justify-center w-full h-dvh px-resp">
                    <h1 className="text-4xl md:text-5xl font-semibold uppercase mb-4">{ mainSite?.hero_title }</h1>
                    <p className="text-lg md:text-xl mb-48 md:mb-8">{ mainSite?.hero_subtitle }</p>
                    <div className={`flex flex-row items-center space-x-8  duration-700 ${animations.firetruck.scrollPos > 0.05 && animations.firetruck.scrollPos < 1 ? 'opacity-0' : 'duration-300'}`}>
                        <a href="mailto:contact@rwc-project.de" className="inline-block bg-turquoise hover:bg-turquoise-lighter border-2 border-turquoise hover:border-turquoise-lighter transition duration-150 rounded-full px-6 py-2">Kontakt</a>
                        <a href="#MainVision" className="border-b hover:text-grey-light">Mehr erfahren<ArrowLongRightIcon className="inline-block h-7 ml-2" /></a>
                    </div>
                </div>
                <div className={`sticky top-0 z-20 flex justify-center w-full h-dvh overflow-hidden pointer-events-none transition-scale duration-1000 ${animations.firetruck.loadingStep>=0 && mainSite?.hero_title ? 'scale-100' : 'scale-110'}`}>
                    <canvas className="absolute object-center min-w-full h-screen pr-64 md:pr-0 pointer-events-none" ref={firetruckCanvasRef}></canvas>
                    <canvas className={`absolute object-center min-w-full h-screen pr-64 md:pr-0 motion-reduce:animate-none animate-bluelightBlink pointer-events-none`} ref={firetruckBCanvasRef}></canvas>
                    <div className={`absolute left-0 bottom-0 flex flex-row justify-center items-center w-full py-5 animate-bounce transition duration-150 ${animations.firetruck.scrollPos>0.9 ? 'opacity-0' : 'duration-300'}`}>
                        <ArrowUturnDownIcon className="inline-block text-white h-4 mr-4" />
                        Scrolle nach unten für mehr Informationen
                    </div>
                </div>
            </div>

            {/** vision section **/}
            <div id="MainVision" className={`MainProblem relative z-10 flex flex-col justify-center text-white w-full min-h-screen ${animations.firetruck.loadingStep>0 ? '-mt-screen' : ''}`}>
                <div className="grid lg:grid-rows-1 lg:grid-cols-2 gap-16 items-center px-resp py-16 lg:py-0">
                    <div>
                        <h2 className="gradient text-3xl font-semibold mb-4">{ mainSite?.vision_title }</h2>
                        <p className="mb-8" dangerouslySetInnerHTML={{__html:mainSite?.vision_content}}></p>
                        <div>
                            <Link to="/about" className="border-b hover:text-grey-light py-2">Mehr erfahren <ArrowLongRightIcon className="inline-block h-7 ml-2" /></Link>
                        </div>
                    </div>
                    <div className={`relative lg:order-first`}>
                        { /*mainSite?.vision_image ?
                                <img src={cockpitUrl+'/storage/uploads'+mainSite?.vision_image?.path} alt="" className="aspect-[4/3] w-full rounded-lg" /> : null
                        */}
                        <LazyMedia
                            className="aspect-[4/3] w-full rounded-lg"
                            cockpitUrl={cockpitUrl}
                            type="image"
                            assetLink={mainSite?.vision_image}
                        />
                        <div className="group/taggings absolute top-0 aspect-[4/3] h-full" onClick={e=>setVisTaggings(v=>{return !v})}>
                            <div className={`absolute left-[34.4%] top-[54%] -translate-x-1/2 pt-3 origin-top-center ${visTaggings?'scale-100':'scale-0'} duration-300 ease-in-out`}>
                                <a href={"https://www.linkedin.com/in/nikolas-hofmann"} target="_blank" className="relative flex flex-row items-center bg-grey/80 backdrop-blur-sm px-2 py-0.5 rounded-md">
                                    Nikolas
                                    <img src={logo_linkedin} alt="" className="h-3 ml-1"/>
                                    <span className="absolute top-0 left-1/2 bg-grey/80 backdrop-blur-sm h-3 w-3 -translate-x-1/2 -translate-y-1/2 -rotate-45 triangle"></span>
                                </a>
                            </div>
                            <div className={`absolute left-[55.2%] top-[57%] -translate-x-1/2 pt-3 origin-top-center scale-0 ${visTaggings?'scale-100':'scale-0'} duration-300 ease-in-out touch-none`}>
                                <a href={"https://www.linkedin.com/in/marcoscalese"} target="_blank" className={`relative flex flex-row items-center bg-grey/80 backdrop-blur-sm px-2 py-0.5 rounded-md`}>
                                    Marco
                                    <img src={logo_linkedin} alt="" className="h-3 ml-1"/>
                                    <span className="absolute top-0 left-1/2 bg-grey/80 backdrop-blur-sm h-3 w-3 -translate-x-1/2 -translate-y-1/2 -rotate-45 triangle"></span>
                                </a>
                            </div>
                            <div className="absolute right-0 bottom-0 flex flex-row items-center p-2">
                                <span className="bg-grey/40 text-sm whitespace-nowrap rounded-full pl-3 pr-10 py-1 translate-x-8">Klick mich!</span>
                                <UserIcon className="inline-block bg-grey/40 backdrop-blur-sm text-white h-8 p-2 rounded-full" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/** routes section **/}
            <div className="relative bg-grey-darkest h-15screen" ref={routesSectionRef}>
                <div className="sticky top-0 flex flex-col justify-center h-screen">
                    {/* <LazyMedia
                            className="w-full"
                            cockpitUrl={cockpitUrl}
                            type="video"
                            assetLink={mainSite?.routes_video}
                            fallback={mainSite?.routes_video_fallback}
                            preview={mainSite?.routes_video_preview}
                            attrs={{
                                'controls': false,
                                'autoPlay': true,
                                'playsInline': true,
                                'muted': true,
                                'loop': false,
                                'ref': routesVideoRef,
                                'onEnded': ()=>setTimeout(()=>routesVideoRef.current.play(), 3000)
                            }}
                        /> */}
                    <video className="absolute top-0 object-cover md:object-[center_10%] lg:object-[center_20%] h-screen w-full" controls={false} autoPlay={true} playsInline={true} muted={true} loop={false} ref={routesVideoRef} onEnded={()=>setTimeout(()=>routesVideoRef.current.play(), 3000)}>
                        {mainSite?.routes_video ?<source src={cockpitUrl+'/storage/uploads'+mainSite?.routes_video?.path} type={mainSite?.routes_video?.mime} /> : null}
                        {mainSite?.routes_video_fallback ? <source src={cockpitUrl+'/storage/uploads'+mainSite?.routes_video_fallback?.path} type={mainSite?.routes_video_fallback?.mime} /> : null}
                    </video>
                    <div className="absolute top-0 left-0 h-full w-full">
                        <div className="absolute flex flex-row justify-center items-center bottom-0 bg-gradient-to-b from-transparent via-black/80 to-black px-resp py-32 opacity-0" ref={routesTextRef}>
                            <div className="flex flex-col lg:grid grid-rows-2 lg:grid-rows-1 lg:grid-cols-2 gap-8">
                                <h2 className="text-3xl font-medium lg:text-right whitespace-pre-line">{ mainSite?.routes_title ? mainSite?.routes_title.replace('\\n', '\n') : null }</h2>
                                { /* <p>{ mainSite?.routes_content }</p> */ }
                                <p>Oftmals werden bei <span className="text-turquoise-light font-semibold">Routen mit nicht einsehbaren Bahnübergängen</span> immer alternative Umfahrungen genutzt, um unkalkulierbare Wartezeiten zu vermeiden. <span className="text-red font-semibold">Umwege ohne Bahnübergang</span> sind besser kalkulierbar - aber unter Umständen wesentlich länger.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/** map section **/}
            <div className="grid lg:grid-rows-1 lg:grid-cols-2 gap-16 items-center bg-white text-black font-medium lg:h-screen px-resp py-16 lg:py-0">
                <div className="">
                    <h2 className="gradient text-3xl font-semibold mb-4">{ mainSite?.map_title }</h2>
                    <p className="">{ mainSite?.map_content }</p>
                </div>
                <div className="relative">
                    <video className="lg:aspect-square object-fit w-full" controls={false} autoPlay={true} playsInline={true} muted={true} loop={false} ref={mapVideoRef}>
                        {mainSite?.map_video_fallback ? <source src={cockpitUrl+'/storage/uploads'+mainSite?.map_video_fallback?.path} type={mainSite?.map_video_fallback?.mime} /> : null}
                        {mainSite?.map_video ? <source src={cockpitUrl+'/storage/uploads'+mainSite?.map_video?.path} type={mainSite?.map_video?.mime} /> : null}
                    </video>
                    <div className="absolute top-0 left-0 h-full w-full"
                         onClick={_=>{mapVideoRef.current.currentTime=0;mapVideoRef.current.play()}}
                    ></div> {/* fix scroll issue on touch */}
                </div>
            </div>


            {/** stats section **/}
            <div className="relative flex flex-col md:flex-row justify-evenly bg-white text-black font-medium min-h-screen px-resp py-32">
                <div className="absolute top-0 left-0 flex flex-col md:flex-row h-full">
                    { mainSite?.stats_proportion_backdrop ?
                        <img src={cockpitUrl+'/storage/uploads'+mainSite?.stats_proportion_backdrop?.path} alt="" className="object-cover md:w-1/2 h-full" /> : null
                    }
                    { mainSite?.stats_time_backdrop ?
                        <img src={cockpitUrl+'/storage/uploads'+mainSite?.stats_time_backdrop?.path} alt="" className="object-cover md:w-1/2 h-full" /> : null
                    }
                </div>
                <div className="absolute top-0 left-0 bg-gradient-to-b md:bg-gradient-to-r from-white/75 via-white to-white/75 w-full h-full"></div>
                <div className="relative flex flex-col items-center space-y-8 p-8 md:w-1/2 xl:w-1/3">
                    <video className="z-10 object-fit h-56 rounded-2xl" controls={false} autoPlay={true} playsInline={true} muted={true} loop={false} ref={statsProportionVideoRef}>
                        {mainSite?.stats_proportion_video_fallback ? <source src={cockpitUrl+'/storage/uploads'+mainSite?.stats_proportion_video_fallback?.path} type={mainSite?.stats_proportion_video_fallback?.mime} /> : null}
                        {mainSite?.stats_proportion_video ? <source src={cockpitUrl+'/storage/uploads'+mainSite?.stats_proportion_video?.path} type={mainSite?.stats_proportion_video?.mime} /> : null}
                    </video>
                    <div className="absolute z-10 top-0 left-0 h-full w-full"
                         onClick={_=>{statsProportionVideoRef.current.currentTime=0;statsProportionVideoRef.current.play()}}
                    ></div> {/* fix scroll issue on touch */}
                    <p className="z-10 flex flex-col grow justify-center text-center">{ mainSite?.stats_proportion_content }</p>
                </div>
                <div className="relative flex flex-col items-center space-y-8 p-8 md:w-1/2 xl:w-1/3">
                    <video className="z-10 object-fit h-56 rounded-2xl" controls={false} autoPlay={true} playsInline={true} muted={true} loop={false} ref={statsTimeVideoRef}>
                        {mainSite?.stats_time_video_fallback ? <source src={cockpitUrl+'/storage/uploads'+mainSite?.stats_time_video_fallback?.path} type={mainSite?.stats_time_video_fallback?.mime} /> : null}
                        {mainSite?.stats_time_video ? <source src={cockpitUrl+'/storage/uploads'+mainSite?.stats_time_video?.path} type={mainSite?.stats_time_video?.mime} /> : null}
                    </video>
                    <div className="absolute z-10 top-0 left-0 h-full w-full"
                         onClick={_=>{statsTimeVideoRef.current.currentTime=0;statsTimeVideoRef.current.play()}}
                    ></div> {/* fix scroll issue on touch */}
                    <p className="z-10 flex flex-col grow justify-center text-center">{ mainSite?.stats_time_content }</p>
                </div>

                {/** report banner **/}
                <Link to="/bericht-anfordern" className="absolute left-0 bottom-0 z-20 flex flex-row justify-center bg-gradient-to-b from-transparent via-white to-transparent w-full px-resp translate-y-1/2">
                    <div className="flex flex-row justify-end items-center bg-turquoise border-4 border-turquoise rounded-full w-full md:w-1/2 xl:w-2/3" ref={reportBannerRef}>
                        <p className="flex-grow font-semibold text-white text-center px-4 hidden opacity-0" ref={reportBannerTextRef}>
                            <span className="hidden xl:inline whitespace-pre-line">{ mainSite?.banner_report_content ? mainSite?.banner_report_content.replace('\\n', '\n') : null }</span>
                            <span className="xl:hidden">{ mainSite?.banner_report_content ? mainSite?.banner_report_content_short.replace('\\n', '\n') : null }</span>
                        </p>
                        <div className="flex flex-col justify-center items-center bg-white transition duration-150 h-16 aspect-square rounded-full" ref={reportBannerCircleRef}>
                            <FontAwesomeIcon icon={faFileLines} className="text-turquoise font-semibold h-8 scale-100 rotate-0" ref={reportBannerIconRef} />
                        </div>
                    </div>
                </Link>
            </div>


            {/** implementation section **/}
            <div id="implementationAnimation" className="relative bg-white text-black font-medium w-full h-6screen" ref={implementationAnimationRef}>
                <div className="sticky top-0 text-white text-xl md:text-2xl h-screen overflow-hidden">
                    <div className="flex flex-col justify-center items-center h-full w-full overflow-hidden rounded-3xl scale-[0.7]" ref={implementationWrapperRef}>
                        <canvas className="h-full lg:h-auto lg:w-full" ref={implementationCanvasRef}></canvas>
                    </div>
                    <div className="absolute top-0 left-0 flex flex-col justify-center items-center bg-black/50 md:bg-transparent h-screen w-full px-resp md:px-0 opacity-0" ref={implementationText1Ref}>
                        <p className="text-center md:w-1/2">
                            Unser Gerät im Einsatzfahrzeug empfängt stetig GPS-Signale um seinen aktuellen Standort zu ermitteln.
                        </p>
                    </div>
                    <div className="absolute top-0 left-0 flex md:block flex-col justify-center items-center bg-black/50 md:bg-transparent h-screen w-full px-resp md:px-0 opacity-0" ref={implementationText2Ref}>
                        <p className="md:absolute md:top-[60%] md:left-1/2 text-center md:text-start md:w-2/5">
                            In regelmäßigen Abständen wird über das Mobilfunknetz eine Anfrage an unsere Server mit der aktuellen Position des Fahrzeugs übermittelt.
                        </p>
                    </div>
                    <div className="absolute top-0 left-0 flex md:block flex-col justify-center items-center bg-black/50 md:bg-transparent h-screen w-full px-resp md:px-0 opacity-0" ref={implementationText3Ref}>
                        <p className="md:absolute md:top-1/2 md:left-16 lg:left-24 xl:left-32 text-center md:text-start md:w-1/2">
                            Unsere Server verarbeitet die von MINOS prognostizierten Öffnungs- und Schließzeiten für alle umliegenden Bahnübergänge und erstellt basierend auf dem aktuellen Standort des Fahrzeugs eine Prognose.
                        </p>
                    </div>
                    <div className="absolute top-0 left-0 flex md:block flex-col justify-center items-center bg-black/50 md:bg-transparent h-screen w-full px-resp md:px-0 opacity-0" ref={implementationText4Ref}>
                        <p className="md:absolute md:top-[60%] md:left-1/2 text-center md:text-start md:w-2/5">
                            Diese Prognose wird dem Fahrenden auf unserem Gerät visualisiert.
                        </p>
                    </div>
                </div>
            </div>


            {/** terminal section **/}
            <div id="terminalAnimation">
                <div className="relative bg-grey-darkest text-white" ref={terminalAnimationRef}>
                    <div className="sticky top-0 flex flex-col justify-end items-center bg-gradient-to-b from-transparent to-grey-darkest min-h-screen">
                        <div className="flex flex-row justify-center bg-grey-darkest w-full">
                            <canvas className="w-72 md:w-138 -translate-y-1/3" ref={terminalCanvasRef}></canvas>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center px-resp pt-32 pb-72 md:pb-138 xl:pb-1/3 -mt-screen">
                        <h2 className="gradient text-3xl font-semibold w-full xl:w-2/3 mb-4">{ mainSite?.terminal_title }</h2>
                        <p className="text-lg xl:w-2/3">{ mainSite?.terminal_content }</p>
                    </div>
                </div>
                <div className="flex flex-col items-center bg-grey-darkest px-resp pt-8 md:pt-0 pb-32">
                    <div className="grid grid-cols-2 md:grid-cols-6 gap-24 text-center xl:w-2/3">
                        <div className="col-span-2 flex flex-col items-center">
                            <img src={icon_wifi} alt="" className="h-16 xl:h-20" />
                            <span className="text-lg mt-4">WLAN-Funktionalität</span>
                        </div>
                        <div className="col-span-2 flex flex-col items-center">
                            <img src={icon_simcard} alt="" className="h-16 xl:h-20" />
                            <span className="text-lg mt-4">Datenübertragung über das Mobilfunknetz</span>
                        </div>
                        <div className="col-span-2 flex flex-col items-center">
                            <img src={icon_security} alt="" className="h-16 xl:h-20" />
                            <span className="text-lg mt-4">Verschlüsselte Kommunikation</span>
                        </div>
                        <div className="dummy hidden md:block"></div>
                        <div className="col-span-2 flex flex-col items-center">
                            <img src={icon_orbit} alt="" className="h-16 xl:h-20" />
                            <span className="text-lg mt-4">Präzise Standortbestimmung per GPS</span>
                        </div>
                        <div className="col-span-2 flex flex-col items-center">
                            <img src={icon_chip} alt="" className="h-16 xl:h-20" />
                            <span className="text-lg mt-4">Leistungsstarker ESP32-Prozessor</span>
                        </div>
                        <div className="dummy md:block"></div>
                    </div>
                </div>
            </div>

            <Footer theme={'dark'} />
        </section>
    );
}

export default Main;
