import React, {Suspense, useEffect, useRef, useState} from 'react';
import { Helmet } from "react-helmet";
import {
    Routes,
    Route,
    Outlet,
    Link,
    NavLink,
    useLocation
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Cookies from 'js-cookie';

import { Loader } from '@googlemaps/js-api-loader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faXmark, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { ChevronRightIcon, ArrowLongLeftIcon } from '@heroicons/react/24/solid'


import ScrollToTop from '../common/ScrollToTop'

import Header from '../common/Header';
import Footer from '../common/Footer';
import PathNav from './PathNav'

import Introduction from './containers/Introduction'
import General from './containers/General'
import Carpool from './containers/Carpool'
import Personal from './containers/Personal'
import Complete from './containers/Complete'
import CookieModal from "../common/CookieModal";



function ReportRequest() {
    let location = useLocation()

    const formEmpty = {
        completedStep: 0, // furthest step in PathNav
        token: null,
        perDu: false,
        org: '',
        address: {
            'name': '',
            'street': '',
            'street_number': '',
            'postal_code': '',
            'locality': '',
            'sublocality': '',
            'location': {
                'lon': 0,
                'lat': 0
            }
        },
        fwType: '',
        strengthTeam: '',
        amountAlarms: '',
        pagerApp: null,
        pagerAppName: '',
        carpool: [
            { "car": "Einsatzleitwagen (ELW, KdoW)", 'amount': 0 },
            { "car": "Mannschaftstransportwagen (MTW, MTF)", 'amount': 0 },
            { "car": "Tanklöschfahrzeuge (TLF, GTLF, TroTLF)", 'amount': 0 },
            { "car": "Hubrettungsfahrzeuge (DLK, GM, TM)", 'amount': 0 },
            { "car": "Löschgruppenfahrzeuge (LF, HLF, VLF, TSF)", 'amount': 0 },
            { "car": "Rüst- und Gerätewagen (RW, GW)", 'amount': 0 },
            { "car": "Schlauch- und Wechselladerfahrzeuge (SW, WLF)", 'amount': 0 },
            { "car": "Sonstige Fahrzeuge (FwK, TL, LKW, LUF)", 'amount': 0 },
            { "car": "Boote (LB, MZB)", 'amount': 0 },
            { "car": "Drohnen", 'amount': 0 }
        ],
        satNav: null,
        satNavBrands: '',
        email: '',
        personalEvaluation: '',
        message: '',
        status: "Unbearbeitet"
    }
    const [formContent, setFormContent] = useState({...formEmpty})

    // sketchy fix of (controlled) radio-buttons, that don't check itself after route-change
    useEffect(() => { setFormContent(f => { return {...f} }) }, [location.pathname]);

    useEffect(() => {
        const prevFormContent = localStorage.getItem('reportRequestForm');
        if (JSON.stringify(formEmpty)===JSON.stringify(formContent) && prevFormContent!==null) // restore formContent from localStorage
            setFormContent(JSON.parse(prevFormContent));
        authenticate() // authenticate with reportRequest-burnerToken-reset is fired on 'Fortfahren' click in Introduction
        // console.log("Cookie rwc_session", Cookies.get('rwc_session')) // = authenticate->data.cookies.token
    }, [])

    useEffect(() => {
        // console.log(formContent)
        const isFormContentUnchanged = JSON.stringify(formEmpty)===JSON.stringify(formContent);

        const prevFormContent = localStorage.getItem('reportRequestForm');
        if ((!isFormContentUnchanged && prevFormContent!==null)
        || (isFormContentUnchanged && prevFormContent===null))  // write formContent to localStorage
            localStorage.setItem('reportRequestForm', JSON.stringify(formContent));
    }, [formContent])


    const authenticate = async (resetToken?) => {
        const url = process.env.NODE_ENV === 'production' ?
            'https://' + window.location.hostname + '/server/authentication.php' + (resetToken?'?p=report_request':'')
            : 'http://localhost:8888/authentication.php' + (resetToken?'?p=report_request':'')
        await fetch(url, {
            'method': 'GET',
            'credentials': 'include' // otherwise every request is a new session
        })
            .then(response => response.json())
            .then(data => {
                if (resetToken) setFormContent(f => { return {...f, 'token': data.burnerToken} });
                if (/*Cookies.get('rwc_session')===undefined ||*/ Cookies.get('rwc_session')!==data.cookies.token) Cookies.set('rwc_session', data.cookies.token, { expires: 365 })
                // console.log('authentication.php fetched.', data);
            });
    }

    const handleSubmit = async () => {
        const url = process.env.NODE_ENV === 'production' ?
            'https://' + window.location.hostname + '/server/report_request.php'
            : 'http://localhost:8888/report_request.php'

        let res = ''
        await fetch(url, {
            'method': 'POST',
            'credentials': 'include', // i need the cookies (session/authent)
            'headers': { 'Content-Type': 'application/json' },
            'mode': 'cors',
            'body': JSON.stringify(formContent)
        })
            .then(response => response.text())
            .then(data => {
                // console.log('report_request.php fetched.', data);
                res = data;
            });
        return res;
    }


    return (
        <section className="ReportRequest bg-white text-black font-montserrat">
            { process.env.NODE_ENV === 'production' ? '' :
                <div className="fixed bg-white text-black z-50 bottom-0 right-0">
                    <div className="hidden sm:block md:hidden">sm</div>
                    <div className="hidden md:block lg:hidden">md</div>
                    <div className="hidden lg:block xl:hidden">lg</div>
                    <div className="hidden xl:block 2xl:hidden">xl</div>
                    <div className="hidden 2xl:block">2xl</div>
                </div>
            }

            <Helmet>
                <meta charSet="utf-8" />
                <title>Bericht anfordern - RWC Project</title>
                <meta name="description" content="Fordern Sie einen kostenlosen, detaillierten Bericht über die Auswirkungen von Bahnübergängen im Einsatzgebiet Ihre Feuerwache an." />
            </Helmet>

            <CookieModal />

            {/*<div className="lg:hidden fixed top-0 left-0 z-50 flex flex-col justify-center bg-white text-black items-center w-screen h-screen text-wrap p-6">Derzeit leider nur auf Tablet (horizontal) und PC möglich.</div>*/}
            <Header />
            <button className="hidden bg-red text-2xl text-white h-12 w-12 rounded-full" onClick={_=>{setFormContent({...formEmpty}); localStorage.setItem('reportRequestForm', '');}}><FontAwesomeIcon icon={faTrashCan} /></button>
            <main className="min-h-screen px-resp pt-8 pb-32">
                <h1 className="text-3xl font-semibold">Auswertung für deine Feuerwehr anfordern</h1>
                <Routes>
                    <Route path="/" element={<></>} />
                    <Route path="*" element={<PathNav setFormContent={setFormContent} formContent={formContent} />} />
                </Routes>
                <ScrollToTop />
                <Suspense fallback={<div>Loading...</div>}>
                    <TransitionGroup component={null} exit={false}>
                        <CSSTransition
                            key={location.pathname}
                            classNames={{
                                appear: "opacity-0",
                                appearActive: "transition-opacity duration-700 opacity-100",
                                appearDone: "",
                                enter: "opacity-0",
                                enterActive: "transition-opacity duration-700 opacity-100",
                                enterDone: "",
                                // exit: "opacity-100",  // this breaks the exit transition
                                exitActive: "transition-opacity duration-500 opacity-0",
                                exitDone: "",
                            }}
                            addEndListener={(node, done) =>
                                node.addEventListener("transitionend", done, false)
                            }
                            unmountOnExit
                        >
                            <Routes>
                                <Route path="/" element={<Introduction setFormContent={setFormContent} formContent={formContent} formEmpty={formEmpty} authenticate={authenticate} />} />
                                <Route path="/general/*" element={<General setFormContent={setFormContent} formContent={formContent} />} />
                                <Route path="/carpool/*" element={<Carpool setFormContent={setFormContent} formContent={formContent} />} />
                                <Route path="/personal/*" element={<Personal setFormContent={setFormContent} formContent={formContent} handleSubmit={handleSubmit}  />} />
                                <Route path="/complete/*" element={<Complete setFormContent={setFormContent} formContent={formContent} clearFormContent={()=>{setFormContent({...formEmpty}); localStorage.setItem('reportRequestForm', JSON.stringify(formEmpty));}} />} />
                            </Routes>
                        </CSSTransition>
                    </TransitionGroup>
                </Suspense>
            </main>
            <Footer theme={'light'} />
        </section>
    );
}

export default ReportRequest;
