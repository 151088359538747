import React from 'react';
import {
    Routes,
    Route,
    Link,
    useParams
} from "react-router-dom";

import Header from '../common/Header';
import Footer from '../common/Footer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faCalendarDays, faBell, faTags, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import ScrollToTop from '../common/ScrollToTop';

function BlogArticle() {
    const { articleUuid } = useParams();

    return (
        <section className="BlogArticle bg-grey-darkest text-white font-montserrat">
            <ScrollToTop />
            <div className="lg:hidden fixed top-0 left-0 z-50 flex flex-col justify-center items-center bg-white text-black w-screen h-screen text-wrap p-6">Derzeit leider nur auf Tablet (horizontal) und PC möglich.</div>
            <Header />
            <main className="min-h-screen px-32 my-16">
                Blog Artikel "{articleUuid}"
            </main>
            <Footer />
        </section>
    );
}

export default BlogArticle;
