import React, {Suspense, useEffect, useRef, useState} from 'react';
import {
    Routes,
    Route,
    Outlet,
    Link,
    NavLink,
    useLocation,
    useNavigate
} from "react-router-dom";
import Cookies from 'js-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCookieBite } from '@fortawesome/free-solid-svg-icons'

function CookieModal() {
    let location = useLocation()
    let navigate = useNavigate();
    useEffect(() => {
        if (location.hash === "#cookieModal") {
            setShowDetails(true);
            setShowModal(true);
            navigate({...location, hash: ""});
        }
    }, [location.hash]);

    /*const cookieConsentsEmpty = {
        essential: 0,
        analytics: 0,
        marketing: 0,
    };*/
    const [cookieConsents, setCookieConsents] = useState(null)

    const [showModal, setShowModal] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    useEffect(() => {
        authenticate()
        // console.log("Cookie rwc_session", Cookies.get('rwc_session')) // = authenticate->data.cookies.token
    }, [])

    const authenticate = async () => {
        const url = process.env.NODE_ENV === 'production'
            ? 'https://' + window.location.hostname + '/server/authentication.php'
            : 'http://localhost:8888/authentication.php';

        await fetch(url, {
            'method': 'GET',
            'credentials': 'include' // otherwise every request is a new session
        })
            .then(response => response.json())
            .then(data => {
                if (/*Cookies.get('rwc_session')===undefined ||*/ Cookies.get('rwc_session')!==data.cookies.token) Cookies.set('rwc_session', data.cookies.token, { expires: 365 })
                setCookieConsents(data.cookies.consents);
                // console.log('authentication.php fetched.', data);

                // change modal visibility based on if essential-timestamp is set
                if (data.cookies?.consents?.essential===0) setShowModal(true);
                else if (data.cookies?.consents?.essential!==0) setShowModal(false);
            });
    }

    const handleAcceptSelection = async () => {
        // setCookieConsents(c => { return {...c, essential: Math.floor(Date.now() / 1000)} }); // hide modal
        setShowModal(false); // hide modal

        const url = process.env.NODE_ENV === 'production' ?
            'https://' + window.location.hostname + '/server/authentication.php'
            : 'http://localhost:8888/authentication.php'

        let formData = new FormData();
        formData.append('cookies', JSON.stringify({
            ...cookieConsents,
            'essential': cookieConsents.essential===0 ? Math.floor(Date.now() / 1000) : cookieConsents.essential
        }));
        await fetch(url, {
            'method': 'POST',
            'credentials': 'include', // i need the cookies (session/authent)
            // 'headers': { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }, // somehow breaks it
            'mode': 'cors',
            'body': formData
        })
            .then(response => response.json())
            .then(data => {
                setCookieConsents(data.cookies?.consents);
                // console.log('handleAcceptSelection() POST. ', data);
            });
    }

    const handleAcceptAll = () => {
        // setCookieConsents(c => { return {...c, essential: Math.floor(Date.now() / 1000)} }); // hide modal
        setShowModal(false); // hide modal

        const url = process.env.NODE_ENV === 'production' ?
            'https://' + window.location.hostname + '/server/authentication.php'
            : 'http://localhost:8888/authentication.php'

        let formData = new FormData();
        formData.append('cookies', JSON.stringify({
            'essential': cookieConsents.essential===0 ? Math.floor(Date.now() / 1000) : cookieConsents.essential,
            'analytics': cookieConsents.analytics===0 ? Math.floor(Date.now() / 1000) : cookieConsents.analytics,
            'marketing': cookieConsents.marketing===0 ? Math.floor(Date.now() / 1000) : cookieConsents.marketing
        }));
        // console.log(formData)
        fetch(url, {
            'method': 'POST',
            'credentials': 'include', // i need the cookies (session/authent)
            // 'headers': { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }, // somehow breaks it
            'mode': 'cors',
            'body': formData
        })
            .then(response => response.json())
            .then(data => {
                setCookieConsents(data.cookies?.consents);
                // console.log('handleAcceptSelection() POST. ', data);
            });
    }


    return (
        !showModal ? undefined :
        <div className="CookieModal fixed z-50 flex flex-col justify-center items-center bg-black/80 text-base w-screen h-screen">
            <div className="bg-grey-darkest text-white border-2 border-grey-light md:w-3/5 lg:w-1/2 xl:w-2/5 overflow-y-scroll mx-4 my-32 sm:my-4 p-8 rounded-2xl">
                <div className={!showDetails ? "flex flex-col space-y-8" : "hidden"}>
                    <div className="flex flex-row items-center">
                        <FontAwesomeIcon  className="text-2xl" icon={faCookieBite} />
                        <span className="text-xl ml-4">Diese Webseite verwendet Cookies</span>
                    </div>
                    <p className="text-justify">
                        Wir und ausgewählte Dritte setzen Cookies oder ähnliche Technologien für technische Zwecke ein und - mit Ihrer Einwilligung - für andere Zwecke, wie in unserer <Link to="/cookies" className="text-turquoise underline">Cookie-Erklärung</Link> beschrieben.
                        Im Fall der Ablehnung könnten bestimmte Funktionen nicht verfügbar sein. Ihre Einwilligung können Sie jederzeit erteilen, verweigern oder widerrufen.
                        Verwenden Sie den „Akzeptieren“-Button, um dem Einsatz solcher Technologien zuzustimmen. Verwenden Sie den „Ablehnen“-Button, um fortzufahren ohne zuzustimmen.
                    </p>
                    <div className="grid grid-cols-2 gap-4">
                        <button className="col-span-2 md:col-span-1 bg-turquoise hover:bg-turquoise-lighter transition duration-150 rounded-full px-6 py-2" onClick={handleAcceptSelection}>Ablehnen</button>
                        <button className="col-span-2 md:col-span-1 bg-turquoise hover:bg-turquoise-lighter transition duration-150 rounded-full px-6 py-2" onClick={handleAcceptAll}>Alle Akzeptieren</button>
                        <button className="col-span-2 hover:text-grey-light border-2 border-white hover:border-grey-light rounded-full px-6 py-2" onClick={_=>setShowDetails(s=>!s)}>Cookies verwalten</button>
                    </div>
                    <div className="flex flex-col md:flex-row justify-center items-center md:space-x-2 space-y-2 md:space-y-0">
                        <Link to="/cookies">Cookie-Erklärung</Link>
                        <span className="hidden md:inline">•</span>
                        <Link to="/datenschutz">Datenschutz</Link>
                        <span className="hidden md:inline">•</span>
                        <Link to="/impressum">Impressum</Link>
                    </div>
                </div>
                <div className={showDetails ? "flex flex-col space-y-8" : "hidden"}>
                    <div className="flex flex-row items-center">
                        <FontAwesomeIcon  className="text-2xl" icon={faCookieBite} />
                        <span className="text-xl ml-4">Diese Webseite verwendet Cookies</span>
                    </div>
                    <div className="flex flex-col space-y-4">
                        <div className="">
                            <div className="flex flex-row items-center space-x-2 font-semibold mb-2">
                                <input id="cookie-essential"
                                       type="checkbox"
                                       checked={true}
                                       disabled={true}
                                       className="bg-grey-lightest text-turquoise focus:ring-turquoise"
                                />
                                <label htmlFor="cookie-essential">Technisch Notwendige</label>
                            </div>
                            <p>Notwendige Cookies tragen dazu bei, eine Website funktionsfähig zu machen, indem sie grundlegende Funktionen wie die Navigation zwischen Seiten und den Zugriff auf sichere Bereiche der Website ermöglichen. Ohne diese Cookies kann die Website nicht ordnungsgemäß funktionieren.</p>
                        </div>
                        <div className="">
                            <div className="flex flex-row items-center space-x-2 font-semibold mb-2">
                                <input id="cookie-analytics"
                                       type="checkbox"
                                       checked={cookieConsents?.analytics > 0}
                                       onChange={e=>setCookieConsents(f => { return {...f, 'analytics': e.target.checked ? Math.floor(Date.now() / 1000) : 0 } })}
                                       className="bg-grey-lightest text-turquoise focus:ring-turquoise"
                                />
                                <label htmlFor="cookie-analytics">Statistiken</label>
                            </div>
                            <p>Statistik-Cookies unterstützen Website-Betreiber dabei, das Nutzerverhalten auf ihren Websites zu verstehen. Sie sammeln und melden anonymisierte Informationen über die Interaktionen der Besucher mit den Webseiten.</p>
                        </div>
                        <div className="">
                            <div className="flex flex-row items-center space-x-2 font-semibold mb-2">
                                <input id="cookie-marketing"
                                       type="checkbox"
                                       checked={cookieConsents?.marketing > 0}
                                       onChange={e=>setCookieConsents(f => { return {...f, 'marketing': e.target.checked ? Math.floor(Date.now() / 1000) : 0 } })}
                                       className="bg-grey-lightest text-turquoise focus:ring-turquoise"
                                />
                                <label htmlFor="cookie-marketing">Marketing</label>
                            </div>
                            <p>Marketing-Cookies dienen dazu, die Aktivitäten von Besuchern auf Websites zu verfolgen. Ihr Ziel ist es, Anzeigen anzuzeigen, die für den einzelnen Benutzer relevant und ansprechend sind. Dadurch werden die Anzeigen wertvoller für Publisher und werbetreibende Drittparteien.</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <button className="col-span-2 md:col-span-1 hover:text-grey-light border-2 border-white hover:border-grey-light rounded-full px-6 py-2" onClick={handleAcceptSelection}>Auswahl bestätigen</button>
                        <button className="col-span-2 md:col-span-1 bg-turquoise hover:bg-turquoise-lighter transition duration-150 rounded-full px-6 py-2" onClick={handleAcceptAll}>Alle auswählen</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CookieModal;
