import React from 'react';
import {
    Routes,
    Route,
    Link,
    useParams
} from "react-router-dom";

import BlogArticles from './BlogArticles';
import BlogArticle from './BlogArticle';


function Blog() {
    return (
        <Routes>
            <Route index path="/" element={<BlogArticles />} />
            <Route path=":articleUuid" element={<BlogArticle />} />
        </Routes>
    );
}

export default Blog;
