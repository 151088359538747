import React, { useRef, useState, useEffect } from 'react';
import {
    Link,
    useNavigate
} from "react-router-dom";

import { Loader } from '@googlemaps/js-api-loader';

import { ArrowLongLeftIcon } from '@heroicons/react/24/solid'


import imgFirefighterUploading from '../../../assets/firefighter_uploading.svg';


function Personal(props) {
    const navigate = useNavigate()
    const form = useRef(null)

    return (
        <form ref={form} className="ReportRequest-Personal">
            <div className="flex flex-row"> {/* fix the full width */}
                <Link className="flex flex-row items-center border-b-1 border-black mb-16" to="../carpool" state={{step:2}}>
                    <ArrowLongLeftIcon className="stroke-0.5 h-6 mr-2" />
                    Zurück
                </Link>
            </div>
            <div className="flex flex-col md:flex-row">
                <div className="md:w-3/5 xl:w-1/2">
                    <h2 className="text-2xl font-semibold">Kontaktdaten und Anmerkungen</h2>
                    <div className="space-y-8 my-8">
                        <div className="relative">
                            <input type="email" id="email" className="bg-grey-lightest border-0 appearance-none focus:outline-none focus:ring-0 w-full px-4 pb-2 pt-5 rounded-xl peer" placeholder=" " required={true}
                                   value={props.formContent?.email}
                                   onChange={e=>props.setFormContent(f => { return {...f, 'email': e.target.value } })} />
                            <label htmlFor="email" className="absolute text-grey-lighter duration-300 transform -translate-y-3.5 scale-75 top-3.5 z-10 origin-[0] left-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3.5">E-Mail</label>
                        </div>
                        <div className="flex flex-col">
                            <span className="mb-4">Wie problematisch empfinden Sie das Problem mit den Bahnübergängen auf ihren Einsatzfahrten?</span>
                            <ul className="flex flex-col xl:flex-row xl:items-center justify-center xl:justify-start space-y-2 xl:space-y-0 xl:space-x-6 flex-wrap">
                                {[
                                    ['unwesentlich', 'choice-irrelevant'],
                                    ['bedeuted', 'choice-relevant'],
                                    ['dringend', 'choice-urgent'],
                                    ['kritisch', 'choice-critical'],
                                ].map(([name, id], index) => (
                                    <li key={"personal-evaluation"+index} className="flex flex-row items-center space-x-2">
                                        <input id={id} name="personal-evaluation" type="radio" className="bg-grey-lightest text-turquoise focus:ring-turquoise" value={name} required={true}
                                               checked={props.formContent?.personalEvaluation === name}
                                               onChange={e=>props.setFormContent(f => { return {...f, 'personalEvaluation': e.target.value } })} />
                                        <label htmlFor={id}>{ name }</label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="relative">
                            <textarea id="additional" rows={5} className="bg-grey-lightest border-0 appearance-none focus:outline-none focus:ring-0 w-full px-4 pb-2 pt-5 rounded-xl peer" placeholder=" "
                                      value={props.formContent?.message}
                                      onChange={e=>props.setFormContent(f => { return {...f, 'message': e.target.value } })}></textarea>
                            <label htmlFor="additional" className="absolute text-grey-lighter duration-300 transform -translate-y-3.5 scale-75 top-3.5 z-10 origin-[0] left-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3.5">Fragen / Anmerkungen</label>
                        </div>
                        <p className="text-sm">Es gelten unsere <Link to="/agb" className="font-medium underline">Allgemeinen Geschäftsbedingungen</Link>.<br /> Informationen zum Datenschutz finden Sie in unserer <br className="hidden md:block" /><Link to="/datenschutz" className="font-medium underline">Datenschutzerklärung</Link>.</p>
                        <button className="inline-block bg-turquoise hover:bg-turquoise-lighter text-white border-2 border-turquoise hover:border-turquoise-lighter transition duration-150 px-6 py-2 rounded-full"
                                onClick={async e => {
                                    e.preventDefault()
                                    if (form.current.reportValidity()) {
                                        const submitRes = await props.handleSubmit();
                                        if (submitRes === 'success') navigate('../complete', {state: {step:4}})
                                        else console.log('handleSubmit() error.', submitRes)
                                    };
                                }}>Bericht anforden</button>
                    </div>
                </div>
                <div className="relative flex flex-col justify-center items-center md:items-end md:w-2/5 xl:w-1/2">
                    <img src={imgFirefighterUploading} alt="Feuerwehrmann mit Formular" className="md:absolute w-72 mt-8 md:my-0"/>
                </div>
            </div>
        </form>
    );
}

export default Personal;
