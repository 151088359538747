import React, {useEffect, useState} from 'react';
import { Helmet } from "react-helmet";

import fetchData from '../common/fetchData'

import ScrollToTop from '../common/ScrollToTop'

import Header from '../common/Header';
import Footer from '../common/Footer';
import CookieModal from "../common/CookieModal";


function CookieDeclaration() {
    const cockpitUrl = 'https://rwc-project.de/cockpit' //process.env.NODE_ENV==='production'? 'http://' + window.location.hostname + ':8888';
    const cockpitKey = 'API-079298e13fc1c7a76ceeebf2f1e2702a305a65ca' // Frontend-Key

    const [i18n, setI18n] = useState('de_DE')
    const [content, setContent] = useState<any>({})
    useEffect(() => {
        console.log('>> CookieDeclaration loaded.')

        fetchData(cockpitUrl, cockpitKey, 'legal', setContent, true, true, i18n, false);
    }, [])

    return (
        <section className="CookieDeclaration bg-white text-black font-montserrat">

            <Helmet>
                <meta charSet="utf-8" />
                <title>Cookie-Erklärung - RWC Project</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <CookieModal />

            <ScrollToTop />
            <Header />
            <main className={`min-h-screen prose px-resp pt-8 pb-32 max-w-none break-words transition-opacity duration-700 ${content?.cookieDeclaration?'opacity-100':'opacity-0'}`} dangerouslySetInnerHTML={{__html:content?.cookieDeclaration}}></main>
            <Footer theme={'light'} />
        </section>
    );
}

export default CookieDeclaration;
