import React, { useRef, useState, useEffect } from 'react';
import {
    Link,
} from "react-router-dom";

import { Loader } from '@googlemaps/js-api-loader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons'


import imgFirestation from '../../../assets/firestation.svg';

import './Introduction.css'


function Introduction(props) {
    /* GoogleMaps Api */
    const loader = new Loader({
        apiKey: "AIzaSyB08agTPyg8hIMs-0kRoUMlc6nh-tyYgvA",
        version: "weekly",
        libraries: ["places"]
    });

    const mapsSearchInput = useRef<HTMLInputElement>(null)
    const options = {
        componentRestrictions: { country: "de" },
        fields: ["name", "address_components", "geometry"],
        strictBounds: false,
        types: ["fire_station", "health", "police", "point_of_interest"], // ["fire_station", "health", "police", "point_of_interest"] oder "establishment"
    };

    const extractPlacesAddress = (place) => {
        // console.log(place)
        const addr = {
            "name": place.name,
            "street": place.address_components.findIndex(ac => ac.types.includes("route")) > -1 ? place.address_components.find(ac => ac.types.includes("route")).long_name : '',
            "street_number": place.address_components.findIndex(ac => ac.types.includes("street_number")) > -1 ? place.address_components.find(ac => ac.types.includes("street_number")).long_name : '',
            "postal_code": place.address_components.findIndex(ac => ac.types.includes("postal_code")) > -1 ? place.address_components.find(ac => ac.types.includes("postal_code")).long_name : '',
            "locality": place.address_components.findIndex(ac => ac.types.includes("locality")) > -1 ? place.address_components.find(ac => ac.types.includes("locality")).long_name : '',
            "sublocality": place.address_components.findIndex(ac => ac.types.includes("sublocality")) > -1 ? place.address_components.find(ac => ac.types.includes("sublocality")).long_name : '',
            "location": { "lon": place.geometry.location.lng(), "lat": place.geometry.location.lat() }, // lon, lat
        }
        console.log(addr)
        return addr
    };

    let autocomplete;
    useEffect(() => {
        loader.load().then((google) => {
            autocomplete = new window.google.maps.places.Autocomplete(mapsSearchInput.current, options);
            window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
                const place = autocomplete.getPlace();
                console.log(place)
                if (place.address_components) {
                    props.setFormContent(f => { return {...f, 'address': extractPlacesAddress(place)} })
                    if (props.formContent?.org === '') // ändert trotzdem auch bei ausgewählter 'org' ???
                        if (place.name?.includes('Feuerwehr') || place.name?.includes('feuerwehr'))
                            props.setFormContent(f => {
                                return {...f, 'org': 'Feuerwehr'}
                            })
                        else
                            props.setFormContent(f => {
                                return {...f, 'org': 'Andere'}
                            })

                    if (place.name?.includes('Freiwillige Feuerwehr'))
                        props.setFormContent(f => {
                            return {...f, 'fwType': 'Freiwillige Feuerwehr'}
                        })
                    else if (place.name?.includes('Berufsfeuerwehr'))
                        props.setFormContent(f => {
                            return {...f, 'fwType': 'Berufsfeuerwehr'}
                        })
                    else if (place.name?.includes('Werkfeuerwehr'))
                        props.setFormContent(f => {
                            return {...f, 'fwType': 'Werkfeuerwehr'}
                        })
                }
            });
        })
    }, [])

    let [isManualAddress, setIsManualAddress] = useState(false)

    const form = useRef(null)


    return (
        <form ref={form} className="ReportRequest-Introduction">
            <div className="flex flex-col md:flex-row">
                <div className="space-y-4 my-16 md:w-3/5">
                    <p>Wir haben über die gesamte Projektlaufzeit zu nahezu jeder Feuerwehr und jedem Bahnübergang zahlreiche Daten zusammengetragen und analysiert.</p>
                    <p>Die Ergebnisse unserer deutschlandweiten Studien möchten wir in Form eines ausführlichen Berichts an interessierte Feuerwehren herausgeben. Somit können Sie sich selbst ein Bild von der möglichen Problematik in Ihrem Einsatzgebiet machen sowie die eventuelle Notwendigkeit einer Lösung abwägen.</p>
                    <p>Interesse? Einfach das Formular ausfüllen und Sie bekommen völlig kostenfrei einen individuellen Bericht von uns per Mail zugesendet.</p>
                </div>
                <div className="flex flex-col justify-center items-center md:items-end md:w-2/5">
                    <img src={imgFirestation} alt="Feuerwehrwache" className="w-72"/>
                </div>
            </div>
            <ul className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-6 mb-8 mt-16 md:mt-0">
                <li>Wir sind eine...</li>
                <li className="flex flex-row items-center space-x-2">
                    <input id="choice-fw" name="org" type="radio" className="bg-grey-lightest text-turquoise focus:ring-turquoise" value={"Feuerwehr"} required={true}
                           checked={props.formContent?.org === "Feuerwehr"}
                           onChange={e=>props.setFormContent(f => { return {...f, 'org': e.target.value } })} />
                    <label htmlFor="choice-fw" className="">Feuerwehr</label>
                </li>
                <li className="flex flex-row items-center space-x-2">
                    <input id="choice-other" name="org" type="radio" className="bg-grey-lightest text-turquoise focus:ring-turquoise" value={"Andere"} required={true}
                           checked={props.formContent?.org === "Andere"}
                           onChange={e=>props.setFormContent(f => { return {...f, 'org': e.target.value } })} />
                    <label htmlFor="choice-other" className="">andere Hilfsorganisation</label>
                </li>
            </ul>
            <div className={`relative ${!isManualAddress ? 'inline-block' : 'hidden'}`}>
                <input type="text"
                       size={42}
                       className="bg-grey-lightest border-0 appearance-none focus:outline-none focus:ring-0 rounded-xl focus:rounded-b-none placeholder-shown:focus:rounded-xl w-full pl-12 pr-10 py-3.5 peer"
                       placeholder={"z.B. Feuerwehr Maintal"}
                       onKeyDown={e=>{ if (e.key === 'Enter') e.preventDefault() }}
                       onFocus={e=>e.target.scrollIntoView({behavior: "smooth", inline: "center"})}
                       ref={mapsSearchInput} />
                <FontAwesomeIcon className="absolute top-1/2 -translate-y-1/2 left-0 text-grey text-lg ml-4 pointer-events-none"  icon={faSearch} />
                <button className="peer-placeholder-shown:hidden" onClick={e=>{
                    e.preventDefault()
                    props.setFormContent(f => { return {...f, 'address': {...props.formEmpty.address} } })
                    mapsSearchInput.current.value = '';
                    mapsSearchInput.current.focus();
                }}>
                    <FontAwesomeIcon className="absolute top-1/2 -translate-y-1/2 right-0 text-grey-light text-lg mr-2 p-2"  icon={faXmark} />
                </button>
            </div>
            <div className={`${isManualAddress ? 'block' : 'hidden'} space-y-4`}>
                <div className="flex flex-row space-x-4"> {/*flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4*/}
                    <div className="relative">
                        <input type="text" id="street" size={32} className="bg-grey-lightest border-0 appearance-none focus:outline-none focus:ring-0 px-4 pb-2 pt-5 rounded-xl peer" placeholder=" " required={props.formContent?.address?.name===''}
                               value={props.formContent?.address?.street}
                               onChange={e=>props.setFormContent(f => { return {...f, 'address': {...f.address, 'street': e.target.value} } })} />
                        <label htmlFor="street" className="absolute text-grey-lighter duration-300 transform -translate-y-3.5 scale-75 top-3.5 z-10 origin-[0] left-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3.5 pointer-events-none">Straße</label>
                    </div>
                    <div className="relative">
                        <input type="text" id="street_number" size={6} className="bg-grey-lightest border-0 appearance-none focus:outline-none focus:ring-0 px-4 pb-2 pt-5 rounded-xl peer" placeholder=" " required={props.formContent?.address?.name===''}
                               value={props.formContent?.address?.street_number}
                               onChange={e=>props.setFormContent(f => { return {...f, 'address': {...f.address, 'street_number': e.target.value} } })} />
                        <label htmlFor="street_number" className="absolute text-grey-lighter duration-300 transform -translate-y-3.5 scale-75 top-3.5 z-10 origin-[0] left-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3.5 pointer-events-none">Nr</label>
                    </div>
                </div>
                <div className="flex flex-row space-x-4">
                    <div className="relative">
                        <input type="text" id="postcode" pattern="\d{5}" size={10} className="bg-grey-lightest border-0 appearance-none focus:outline-none focus:ring-0 px-4 pb-2 pt-5 rounded-xl peer" placeholder=" " required={true}
                               value={props.formContent?.address?.postal_code}
                               onChange={e=>props.setFormContent(f => { return {...f, 'address': {...f.address, 'postal_code': e.target.value} } })} />
                        <label htmlFor="postcode" className="absolute text-grey-lighter duration-300 transform -translate-y-3.5 scale-75 top-3.5 z-10 origin-[0] left-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3.5 pointer-events-none">PLZ</label>
                    </div>
                    <div className="relative">
                        <input type="text" id="city" size={28} className="bg-grey-lightest border-0 appearance-none focus:outline-none focus:ring-0 px-4 pb-2 pt-5 rounded-xl peer" placeholder=" " required={true}
                               value={props.formContent?.address?.locality}
                               onChange={e=>props.setFormContent(f => { return {...f, 'address': {...f.address, 'locality': e.target.value} } })} />
                        <label htmlFor="city" className="absolute text-grey-lighter duration-300 transform -translate-y-3.5 scale-75 top-3.5 z-10 origin-[0] left-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3.5 pointer-events-none">Ort und Ortsteil</label>
                    </div>
                </div>
            </div>
            <div className="">
                { props.formContent?.address?.name && !isManualAddress ?
                    <div className="pl-4 pt-4">{
                        <>
                            {props.formContent?.address?.name}<br />
                            {props.formContent?.address?.street} {props.formContent?.address?.street_number} <br />
                            {props.formContent?.address?.postal_code} {props.formContent?.address?.locality}  {props.formContent?.address?.sublocality !== null ? props.formContent?.address?.sublocality : ''}
                        </>
                    }</div> : ''
                }
                { props.formContent?.address?.name || isManualAddress ?
                    <div className="flex flex-row items-center pt-8">
                        <Link to="../general" state={{step:1}} className="inline-block bg-turquoise hover:bg-turquoise-lighter text-white border-2 border-turquoise hover:border-turquoise-lighter transition duration-150 px-6 py-2 rounded-full"
                              onClick={e=>{
                                  if (!form.current.reportValidity()) e.preventDefault()
                                  else props.authenticate(true)
                              }}>Fortfahren</Link>
                        <button className="inline-block border-black border-2 hover:bg-white hover:text-black transition duration-150 px-6 py-2 rounded-full ml-4" onClick={e=>{
                            e.preventDefault() // prevent form submit
                            if (!isManualAddress) setIsManualAddress(true)
                            else {
                                setIsManualAddress(false)
                                props.setFormContent(f => { return {...f, 'address': {...props.formEmpty.address} } })
                                mapsSearchInput.current.value = '';
                                mapsSearchInput.current.focus();
                            }
                        }}>{ !isManualAddress ? 'Ändern' : 'Suchleiste anzeigen' }</button>
                    </div> : <button className="block pt-3 pl-6 text-sm underline" onClick={e => {
                        e.preventDefault()
                        setIsManualAddress(s=>!s)
                    }}>
                        Ihre Feuerwehr ist nicht in der Liste?
                    </button>
                }
            </div>
        </form>
    );
}

export default Introduction;
