import React, { useRef, useEffect } from 'react';
import {
    NavLink,
    useNavigate,
    useLocation
} from "react-router-dom";

import { ChevronRightIcon } from '@heroicons/react/24/solid'



function PathNav(props) {
    const navigate = useNavigate()
    let location = useLocation()

    useEffect(() => {
        console.log("[PathNav] location.state:", location.state)
        if (furthestStep<currStep) props.setFormContent(f => { return {...f, 'completedStep': currStep } })
        if (location.state === null) {
            console.log("Unpermitted route!!!")
            navigate('../')
        }
    }, [location])

    const currStep = location.state?.step;
    const furthestStep = props.formContent.completedStep;

    const isReached = (index:number) => index<=furthestStep||index<=currStep;


    return (
        <nav className="flex flex-row flex-wrap items-center space-x-2 mt-8 mb-16">
            {[
                ['Ihre Feuerwehr', '../'],
                ['Einsatzstatistiken', '../general'],
                ['Fuhrpark', '../carpool'],
                ['Kontaktdaten', '../personal'],
                ['Fertig', '../complete'],
            ].map(([title, url], index) => (
                index === 4 && currStep !== 4 ? '' :
                <NavLink key={"step_arrow"+index}
                         to={url}
                         state={{step:index}}
                         className={({ isActive, isPending }) => "relative flex flex-row items-center " + (isActive ? "font-semibold " : "") + (isReached(index) ? "hover:font-semibold " : "") }
                         onClick={e=>{if (!isReached(index) || currStep===4) e.preventDefault()}}
                >
                    { index !== 0 ? <ChevronRightIcon className={`${!isReached(index)?'text-grey-light':'text-turquoise'} h-7 mr-2`} /> : '' }
                    <span className={`flex flex-col ${!isReached(index)?'text-grey-light':''} whitespace-nowrap`}>
                        { title }
                        <span className="font-semibold h-0 overflow-hidden">{title}</span> {/* hast to be invisible*/}
                    </span>
                </NavLink>
            ))}
        </nav>
    );
}

export default PathNav;
