import React, { useRef, useState, useEffect } from 'react';
import {
    Link,
} from "react-router-dom";

import { Loader } from '@googlemaps/js-api-loader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { ArrowLongLeftIcon } from '@heroicons/react/24/solid'


import imgFirefighterSendLetter from '../../../assets/firefighter_send_letter.svg';

import Infotext from '../Infotext'


function Complete(props) {
    useEffect(() => {
        props.clearFormContent()
    }, [])

    return (
        <section className="ReportRequest-Personal">
            <div className="flex flex-col md:flex-row">
                <div className="md:w-3/5 xl:w-1/2">
                    <h2 className="text-2xl font-semibold">
                        <FontAwesomeIcon icon={faCircleCheck} className="text-green mr-4" />
                        Ihre Anfrage ist bei uns eingegangen
                    </h2>
                    <p className="my-8">
                        Wir freuen uns über Ihr Interesse.<br /><br />
                        Die Auswertung sollte sie innerhalb von 5 Tagen per Mail erreichen.
                    </p>
                </div>
                <div className="relative flex flex-col justify-center items-center md:items-end md:w-2/5 xl:w-1/2">
                    <img src={imgFirefighterSendLetter} alt="Feuerwehrmann mit Formular" className="md:absolute w-72 mt-8 mb-16 md:my-0"/>
                </div>
            </div>
            <Link to="../" state={{step:0}} className="inline-block border-black border-2 hover:bg-white hover:text-black transition duration-150 px-6 py-2 rounded-full">zurück zur Startseite</Link>
        </section>
    );
}

export default Complete;
