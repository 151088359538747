import React, { useRef, useState, useEffect } from 'react';
import {
    Link,
} from "react-router-dom";

import { Loader } from '@googlemaps/js-api-loader';

import { ArrowLongLeftIcon } from '@heroicons/react/24/solid'


import imgFirestation from '../../../assets/firestation.svg';

import Infotext from '../Infotext'


function Carpool(props) {
    /*const [carpool, setCarpool] = useState([
        { "car": "Einsatzleitwagen (ELW, KdoW)", 'amount': 0 },
        { "car": "Mannschaftstransportwagen (MTW, MTF)", 'amount': 0 },
        { "car": "Tanklöschfahrzeuge (TLF, GTLF, TroTLF)", 'amount': 0 },
        { "car": "Hubrettungsfahrzeuge (DLK, GM, TM)", 'amount': 0 },
        { "car": "Löschgruppenfahrzeuge (LF, HLF, VLF, TSF)", 'amount': 0 },
        { "car": "Rüst- und Gerätewagen (RW, GW)", 'amount': 0 },
        { "car": "Schlauch- und Wechselladerfahrzeuge (SW, WLF)", 'amount': 0 },
        { "car": "Sonstige Fahrzeuge (FwK, TL, LKW, LUF)", 'amount': 0 },
        { "car": "Boote (LB, MZB)", 'amount': 0 },
        { "car": "Drohnen", 'amount': 0 }
    ])*/

    const form = useRef(null);


    return (
        <form ref={form} className="ReportRequest-Carpool">
            <div className="flex flex-row"> {/* fix the full width */}
                <Link className="flex flex-row items-center border-b-1 border-black mb-16" to="../general" state={{step:1}}>
                    <ArrowLongLeftIcon className="stroke-0.5 h-6 mr-2" />
                    Zurück
                </Link>
            </div>
            <div className="flex flex-col md:flex-row">
                <div className="space-y-8 md:w-3/5">
                    <h2 className="text-2xl font-semibold">Ihre Fahrzeuge</h2>
                    <p className="">Gerne können Sie uns zur genaueren Auswertung Ihrer Hilfsorganisation nachfolgend weitere Informationen über die Zusammenstellung Ihres  Fuhrparks mitteilen.</p>
                    <Infotext text="Die in den Klammern aufgeführten Fahrzeugabkürzungen sind nicht abschließend. Sollte ein Fahrzeug von Ihnen hier nicht aufgeführt sein, können Sie es einer möglichst Fahrzeug-ähnlichen Kategorie zuordnen." />
                </div>
                <div className="relative flex flex-col justify-center items-center md:items-end md:w-2/5">
                    <img src={imgFirestation} alt="Feuerwehrmann mit Formular" className="md:absolute w-72 mb-8 mt-16 md:my-0"/>
                </div>
            </div>
            <ul className="flex flex-col divide-y divide-turquoise-pale divide-dashed md:w-3/5 my-8">
                { props.formContent?.carpool?.length ?
                    props.formContent?.carpool.map((item, index) =>
                        <li key={"carpool-car"+index} className="flex flex-row justify-between items-center space-x-4 py-2 select-none">
                            <div className="grow hyphens-auto min-w-0" lang="de">{ item.car }</div>
                            <div className="shrink-0 flex flex-row bg-grey-light/20 rounded-lg">
                                <div className="text-lg font-medium px-3" onClick={_=>{
                                    let newCarpool = [...props.formContent?.carpool];
                                    if (newCarpool[index].amount > 0) newCarpool[index].amount--;
                                    props.setFormContent(f => { return {...f, 'carpool': newCarpool } })
                                }}>-</div>
                                <div className="bg-grey-lightest text-center border-0 w-16 py-1">{item.amount}</div>
                                <div className="text-lg font-medium px-3" onClick={_=>{
                                    let newCarpool = [...props.formContent?.carpool];
                                    newCarpool[index].amount++;
                                    props.setFormContent(f => { return {...f, 'carpool': newCarpool } })
                                }}>+</div>
                            </div>
                        </li>
                    ) : ''
                }
            </ul><div className="flex flex-col mb-8">
            <span className="mb-4">Existieren Navigationssysteme in Ihren Fahrzeugen?</span>
            <ul className="flex flex-row items-center justify-start space-x-6">
                {[
                    ['Ja', 'choice-satnav'],
                    ['Nein', 'choice-nosatnav']
                ].map(([name, id], index) => (
                    <li key={"sat-nav"+index} className="flex flex-row items-center space-x-2">
                        <input id={id} name="sat-nav" type="radio" className="bg-grey-lightest text-turquoise focus:ring-turquoise" value={name} required={true}
                               checked={props.formContent?.satNav === (name==='Ja')}
                               onChange={e=>props.setFormContent(f => { return {...f, 'satNav': e.target.value==='Ja', 'satNavBrands': e.target.value==='Ja'?props.formContent?.satNavBrands:null} })} />
                        <label htmlFor={id}>{ name }</label>
                    </li>
                ))}
            </ul>
            <div className={`relative ${props.formContent?.satNav?'':'hidden'} mt-4`}>
                <input type="text" size={42} id="sat-nav-brands" className="bg-grey-lightest border-0 appearance-none focus:outline-none focus:ring-0 px-4 pb-2 pt-5 rounded-xl peer" placeholder=" " required={false}
                       value={props.formContent?.satNavBrands}
                       onChange={e=>props.setFormContent(f => { return {...f, 'satNavBrands': e.target.value } })} />
                <label htmlFor="sat-nav-brands" className="absolute text-grey-lighter duration-300 transform -translate-y-3.5 scale-75 top-3.5 z-10 origin-[0] left-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3.5">Marken der Geräte (optional)</label>
            </div>
        </div>
            <Link to="../personal" state={{step:3}} className="inline-block bg-turquoise hover:bg-turquoise-lighter text-white border-2 border-turquoise hover:border-turquoise-lighter transition duration-150 px-6 py-2 rounded-full"
                  onClick={e=>{if (!form.current.reportValidity()) e.preventDefault()}}>weiter</Link>
        </form>
    );
}

export default Carpool;
