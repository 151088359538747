import React from 'react';
import { Helmet } from "react-helmet";
import {
    Routes,
    Route,
    Link,
    useParams
} from "react-router-dom";

import Header from '../common/Header';
import Footer from '../common/Footer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faCalendarDays, faBell, faTags, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

function BlogArticles() {
    return (
        <section className="BlogArticles bg-grey-darkest text-white font-montserrat">

            <Helmet>
                <meta charSet="utf-8" />
                <title>Neuigkeiten - RWC Project</title>
                <meta name="description" content="Erhalten Sie die aktuellsten Informationen und bekommen Sie interessante Einblicke über die Entwicklung des Projekts." />
            </Helmet>

            <div className="lg:hidden fixed top-0 left-0 z-50 flex flex-col justify-center items-center bg-white text-black w-screen h-screen text-wrap p-6">Derzeit leider nur auf Tablet (horizontal) und PC möglich.</div>
            <Header darkBg={true} />
            <main className="min-h-screen px-32 my-16">
                <div className="relative">
                    <div className="absolute flex flex-row bg-white h-full w-24 -ml-32">
                        <div className="bg-turquoise h-full w-12"></div>
                        <div className="bg-turquoise-lighter h-full w-8"></div>
                        <div className="bg-turquoise-lightest h-full w-4"></div>
                    </div>
                    <h1 className="text-2xl mb-4">Blog</h1>
                </div>
                <h2>Neuigkeiten und Fortschritt des Projekts</h2>
                <div className="flex flex-row justify-between space-x-8 mt-12 mb-8">
                    <div className="flex flex-row space-x-8 flex-grow max-w-2/3">
                        <div className="flex flex-row flex-grow">
                            <input type="text" placeholder="Nach Blogartikel/Kategorie suchen" className="appearance-none flex-grow bg-grey placeholder:text-grey-light rounded-l-xl pl-4 pr-0 py-3 border-0 focus:outline-none focus:ring-0" />
                            <span className="flex flex-row items-center bg-grey text-grey-light rounded-r-xl px-4">
                                <FontAwesomeIcon className="text-xl"  icon={faSearch} />
                            </span>
                        </div>
                        <div className="flex flex-row">
                            <span className="flex flex-row items-center bg-grey text-grey-light rounded-l-xl px-4">
                                <FontAwesomeIcon className="text-xl" icon={faCalendarDays} />
                            </span>
                            <span className="flex flex-row items-center bg-grey rounded-r-xl pr-4">
                                <span className="from">12/2022</span>
                                <span className="text-grey-light font-semibold px-2">-</span>
                                <span className="to">10/2022</span>
                            </span>
                        </div>
                    </div>
                    <Link to="" className="inline-block justify-self-end bg-turquoise hover:bg-turquoise-lighter transition duration-150 rounded-xl px-4 py-3 whitespace-nowrap">
                        <FontAwesomeIcon className="text-xl pr-4" icon={faBell} />
                        Nichts mehr verpassen
                    </Link>
                </div>
                <div className="grid grid-cols-3 gap-8">
                    {
                        [...Array(12)].map((_, i) => <div key={'blog-card-'+i} className="card bg-grey border-b-2 border-turquoise">
                            <div className="relative card-header">
                                <figure>
                                    <img src=" " alt="" className="bg-grey-lighter aspect-[4/3] w-full"/>
                                    <figcaption className=""></figcaption>
                                </figure>
                                <h3 className="absolute bottom-0 bg-grey/80 text-xl font-semibold w-full px-4 py-3">mFund Startup-Pitch Teilnahme</h3>
                                <Link to={""+i} className="absolute top-0 right-0 flex justify-center items-center bg-turquoise/80 w-14 h-14">
                                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                </Link>
                            </div>
                            <div className="card-body space-y-4 p-4">
                                <span className="text-grey-light">13.08.2022</span>
                                <p className="line-clamp-5">Wir sind in Berlin auf dem 4. BMDV Startup-Pitch im Zuge der mFUND Konferenz aufgetreten. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labate ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.</p>
                                <div className="flex flex-row items-center">
                                    <FontAwesomeIcon className="text-turquoise" icon={faTags} />
                                    <p className="ml-2 line-clamp-1">MINOS, Pitch, Förderung</p>
                                </div>
                            </div>
                        </div>)
                    }
                </div>
            </main>
            <Footer />
        </section>
    );
}

export default BlogArticles;
